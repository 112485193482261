import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'sources'
})
export class SourcesPipe implements PipeTransform {
  transform(value: number): string {
    switch (value) {
      case 67:
        return "C";
      case 79:
        return "O";
      case 84:
        return "T";
    }
    return "";
  }
}
