import {Component, Input, OnInit} from '@angular/core';
import {FlockManagementService} from '@core/services/flock-management.service';
import {FarmMastRecord} from '@core/services/flock-management.swagger';

@Component({
  selector: 'app-farm-details',
  templateUrl: './farm-details.component.html',
  styleUrls: ['./farm-details.component.scss']
})
export class FarmDetailsComponent implements OnInit {
  @Input() farm: FarmMastRecord | undefined = undefined;

  constructor(
    private _flockManagementService: FlockManagementService
  ) {
  }

  ngOnInit(): void {
  }

  editTriggered() {
    this._flockManagementService.handleEdit('farm', this.farm!);
  }

  async delete() {
    if (!this.deleteDisabled) {
      await this._flockManagementService.handleDeleteFromCard('farm', this.farm?.__id ?? '');
    }
  }

  navigate() {
    this._flockManagementService.activateItem(this.farm);
  }

  get deleteDisabled() {
    return this._flockManagementService.getItemHasActiveFlock(this.farm!);
  }
}
