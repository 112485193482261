<legacy-meta-cat-data-grid
    *ngIf="dataSource && metadata"
    [dataSource]="dataSource"
    [metadata]="metadata"
    [padding]="14"
    [customTemplate]="status"
    [rowActionTemplate]="rowActions"
    (clickedCell)="handleClickOnHouse($event)"
></legacy-meta-cat-data-grid>

<ng-template #status let-data="data">
  <legacy-meta-cat-status-dot
      [color]="loadStatusColor(data?.status)"
      [tooltip]="loadStatusTooltip(data?.status)"
      class="d-flex justify-content-center"
  ></legacy-meta-cat-status-dot>
</ng-template>

<ng-template #rowActions let-data="data">
  <div class="actions-col">
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="edit(data)">
      <span>Edit</span>
    </button>
    <button mat-menu-item [hidden]="data.status !== 'A'" (click)="delete(data)">
      <span>Delete</span>
    </button>
  </mat-menu>
</ng-template>
