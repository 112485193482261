<legacy-meta-cat-title-bar [title]="'Grower Data'"></legacy-meta-cat-title-bar>
<div class="row-details">
  <div><strong>Grower Number: </strong>{{activeItem?.growerReference}}</div>
  <div><strong>Grower Type: </strong>{{activeItem?.growerType}}</div>
  <div><strong>Replication Date: </strong>{{activeItem?.replicationDateTime | date: 'short'}}</div>
</div>
<div class="row-details">
  <div><strong>Description: </strong>{{activeItem?.description}}</div>
  <div><strong>Address: </strong>{{activeItem?.address}}</div>
  <div><strong>Uses Standard: </strong>{{activeItem?.useStandard ? 'Yes' : 'No'}}</div>
</div>
