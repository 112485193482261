import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {FlockManagementService} from "@core/services/flock-management.service";

@Component({
  selector: 'app-middle-details',
  templateUrl: './middle-details.component.html',
  styleUrls: ['./middle-details.component.scss']
})
export class MiddleDetailsComponent {
  constructor(
    private _flockManagementService: FlockManagementService,
    public dialog: MatDialog
  ) {
  }

  get itemToAdd() {
    switch (this._flockManagementService.activeView) {
      case 'region':
        return 'GROWER';
      case 'grower':
        return 'FARM';
      case 'farm':
        return 'HOUSE';
      case 'house':
        return 'FLOCK';
      default:
        return '';
    }
  }

  get title() {
    switch (this._flockManagementService.activeView) {
      case 'region':
        return 'Growers';
      case 'grower':
        return 'Farms';
      case 'farm':
        return 'Houses';
      case 'house':
        return 'Active Flocks';
      case 'all-flocks':
        return 'Complete List of Flocks';
      default:
        return '';
    }
  }

  get activeView() {
    return this._flockManagementService.activeView;
  }

  addClicked() {
    this._flockManagementService.handleAdd(this.itemToAdd.toLowerCase());
  }
}
