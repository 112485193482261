import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators,} from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MAT_NATIVE_DATE_FORMATS,
  NativeDateAdapter,
} from '@angular/material/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FlockManagementService} from '@core/services/flock-management.service';
import {Grower, Regions} from '@core/services/flock-management.swagger';

@Component({
  selector: 'app-add-grower-modal',
  templateUrl: './add-grower-modal.component.html',
  styleUrls: ['./add-grower-modal.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: NativeDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: MAT_NATIVE_DATE_FORMATS},
    {provide: MAT_DATE_LOCALE, useValue: 'en-US'},
  ],
})
export class AddGrowerModalComponent implements OnInit {
  growerForm: FormGroup = new FormGroup({});

  constructor(
    public dialogRef: MatDialogRef<AddGrowerModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { grower: Grower },
    private formBuilder: FormBuilder,
    private _flockManagementService: FlockManagementService
  ) {
  }

  ngOnInit() {
    this.initializeForm();
  }

  get region(): Regions {
    return this._flockManagementService.activeItem as Regions;
  }

  oneLetterValidator(): ValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      return new Promise((resolve) => {
        const value: string = control.value;
        if (value && (value.length !== 1 || !/[a-zA-Z]/.test(value))) {
          resolve({moreThanOneLetter: true});
        } else {
          resolve(null);
        }
      });
    };
  }

  statusValidator(): ValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      return new Promise((resolve) => {
        const value: number = control.value;
        if (value && (value < 0 || value > 9)) {
          resolve({invalidStatus: true});
        } else {
          resolve(null);
        }
      });
    };
  }

  growerReferenceValidator(): ValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      return new Promise((resolve) => {
        const value = control.value;
        if (value && value.length < 3) {
          return resolve({min: true});
        }
        if (value && this._flockManagementService.growers?.some(g => g.growerReference?.trim().toLowerCase() === value.trim().toLowerCase())) {
          return resolve({nonUnique: true});
        }
        return resolve(null);
      });
    };
  }

  negativeValidator(): ValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      return new Promise((resolve) => {
        const value: number = control.value;
        if (value && value < 0) {
          resolve({isNegative: true});
        } else {
          resolve(null);
        }
      });
    };
  }

  initializeForm() {
    this.growerForm = this.formBuilder.group({
      address: [this.data.grower?.address || '', Validators.required],
      loadAdjustment: [
        this.data.grower?.loadAdjustment || 1,
        Validators.required,
        this.negativeValidator(),
      ],
      erpReference: [this.data.grower?.erpReference || '', Validators.required],
      gradeName: [
        this.data.grower?.gradeName || '',
        Validators.required,
        this.oneLetterValidator(),
      ],
      description: [this.data.grower?.description || '', Validators.required],
      growerReference: [
        {
          value: this.data.grower?.growerReference || '',
          disabled: this.data.grower !== null,
        },
        Validators.required,
        this.growerReferenceValidator(),
      ],
      growerType: [
        this.data.grower?.growerType || '',
        Validators.required,
        this.oneLetterValidator(),
      ],
      processId: [
        this.data.grower?.processId || '',
        Validators.required,
        this.negativeValidator(),
      ],
      replicationDateTime: [
        this.data.grower?.replicationDateTime
          ? new Date(this.data.grower?.replicationDateTime)
          : new Date(),
        Validators.required,
      ],
      serviceMan: [
        this.data.grower?.serviceMan || '',
        Validators.required,
        this.negativeValidator(),
      ],
      status: [
        this.data.grower?.status || '',
        Validators.required,
        this.statusValidator(),
      ],
      useStandard: [this.data.grower?.useStandard || false],
    });
  }

  onSubmit() {
    if (this.growerForm.valid) {
      if (this.data.grower?.__id) {
        this._flockManagementService
          .editRecord('grower', {
            ...this.growerForm.value,
            __id: this.data.grower?.__id,
            guid: this.data.grower?.guid,
            type: 'grower',
            region: this.data.grower?.region,
            growerReference: this.data.grower?.growerReference
          })
          .then(() => {
            this.dialogRef.close();
          });
      } else {
        this._flockManagementService
          .createNewRecord('grower', {
            ...this.growerForm.value,
            type: 'grower',
            region: this.region.region,
          })
          .then(() => {
            this.dialogRef.close();
          });
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
