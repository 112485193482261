import {MakeBooleanProperty, MakeNumberProperty, MakeStringProperty, ModelMetadataBuilder} from '@cat2/legacy-meta-cat';
import {ModelMetadata} from '@cat2/legacy-meta-cat/lib/shared/metadata/model-metadata';

export const LinesProperties = [
  MakeStringProperty('__id', 'ID'),
  MakeNumberProperty('lineID', 'ID'),
  MakeStringProperty('name', 'Name'),
  MakeStringProperty('description', 'Description'),
  MakeStringProperty('lineType', 'Line Type'),
  MakeStringProperty('active', 'Active'),
  MakeStringProperty('processid', 'Site ID'),
  MakeStringProperty('guidProdlineRunIds', 'Line Run IDs'),
  MakeBooleanProperty('canHoldHolds', 'Can Hold Holds'),
  MakeStringProperty('whichHolds', 'Which Holds'),

];

export function LinesMetadataBuilder(): ModelMetadataBuilder {
  return new ModelMetadataBuilder()
    .addProperties(LinesProperties)
    .makeFieldRequired('name')
    .makeFieldRequired('active')
    .giveFieldMaxLength('lineType',40)
    .addGridCustomValidation('lineType', "allows alpha-numeric characters only", (i) => new RegExp(/^\w+$/).test(i?.value))
}

export function LinesOverviewMasterMetadata(): ModelMetadata {
  return LinesMetadataBuilder()
    .apply(LinesGridColumns)
    .apply(LinesForm)
    .setGridAddMode('grid')
    .setGridEditMode('batch')
    .setGridAddMode('grid')
    .disableRowGrouping()
    .disableGridSearch()
    .disableColumnFiltering()
    .create();
}

export function LinesGridColumns(builder: ModelMetadataBuilder): ModelMetadataBuilder {
  return builder
    .addGridTitle('Lines')
    .addGridColumn('lineID')
    .addGridCustomValidation('lineID', "must be positive", (i) => !i?.data?.lineID || i.data.lineID >= 0)
    .addGridCustomValidation('lineID', "must be less than 32767", (i) => !i?.data?.lineID || i.data.lineID < 32767)
    .addGridCustomValidation('lineID', "must be an integer", (i) => !i?.data?.lineID || Number.isInteger(i.data.lineID))
    .addGridColumn('name')
    .addGridColumn('description')
    .addGridColumn('lineType')
    .addGridColumn('active')
    .addGridColumn('processid')
    .addGridColumn('guidProdLineRunIds')
    .addGridColumn('canHoldHolds')
    .addGridColumn('whichHolds');
}

export function LinesForm(builder: ModelMetadataBuilder): ModelMetadataBuilder {
  return builder
    .addFormTitle('Lines')
    .addPropertyToFormRow('lineID', 2)
    .addPropertyToFormRow('name', 2)
    .addPropertyToFormRow('description', 2)
    .addPropertyToFormRow('lineType', 2)
    .addPropertyToFormRow('active', 2)
    .addPropertyToFormRow('processid', 2)
    .addPropertyToFormRow('guidProdLineRunIds', 2)
    .addPropertyToFormRow('canHoldHolds', 2)
    .addPropertyToFormRow('whichHolds', 2);
}
