<legacy-meta-cat-tree
  class="tree"
  [items]="regions"
  [hierarchy]="hierarchy"
  [icons]="icons"
  [messageOnNoItems]="'No items found for the search criteria'"
  [ngStyle]="{'margin.px':  !regions.length ? 18.25 : 0}"
  (activate)="activateItem($event)"
  (rightClickActivate)="rightClickActivateItem($event)"
  (contextmenu)="onRightClick($event)"
></legacy-meta-cat-tree>
<div *ngIf="contextmenu">
  <app-context-menu [x]="contextMenuX" [y]="contextMenuY"></app-context-menu>
</div>
