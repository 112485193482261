import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MAT_NATIVE_DATE_FORMATS,
  NativeDateAdapter,
} from '@angular/material/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FlockManagementService} from '@core/services/flock-management.service';
import {
  Breeds,
  Classifications,
  Flocks,
  FlockSource,
  Gender,
  House,
  Rations,
} from '@core/services/flock-management.swagger';

@Component({
  selector: 'app-add-flock-modal',
  templateUrl: './add-flock-modal.component.html',
  styleUrls: ['./add-flock-modal.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: NativeDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: MAT_NATIVE_DATE_FORMATS},
    {provide: MAT_DATE_LOCALE, useValue: 'en-US'},
  ],
})
export class AddFlockModalComponent implements OnInit {
  flockForm: FormGroup = new FormGroup({});
  genders = this.getLabelValueFromEnum(Gender);
  sources = this.getLabelValueFromEnum(FlockSource);
  disableSubmit = false;

  constructor(
    public dialogRef: MatDialogRef<AddFlockModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { flock: Flocks },
    private formBuilder: FormBuilder,
    private _flockManagementService: FlockManagementService
  ) {
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  getLabelValueFromEnum(enumValue: any): { label: string, value: number }[] {
    return Object.keys(enumValue)
      .filter((key) => isNaN(Number(key)))
      .map((key) => ({label: key, value: parseInt(enumValue[key as any])}));
  }

  get house(): House {
    return this._flockManagementService.activeItem.value as House;
  }

  get breeds(): Breeds[] {
    return this._flockManagementService.breeds || [];
  }

  get classifications(): Classifications[] {
    return this._flockManagementService.classifications || [];
  }

  get rations(): Rations[] {
    return this._flockManagementService.rations || [];
  }

  get houses() {
    if (this._flockManagementService.activeView === 'all-flocks') {
      return this._flockManagementService.houses?.filter(
        (house) =>
          this.data?.flock?.growerReference ===
          house.growerReference &&
          this.data?.flock?.farmNo === house.farmNo
      );
    } else {
      return this._flockManagementService.houses?.filter(
        (house) =>
          (this.house as House).growerReference ===
          house.growerReference &&
          (this.house as House).farmNo === house.farmNo
      );
    }
  }

  get isEditMode(): boolean {
    return !!this.data?.flock?.__id;
  }

  negativeValidator(): ValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      return new Promise((resolve) => {
        const value: number = control.value;
        if (value && value < 0) {
          resolve({isNegative: true});
        } else {
          resolve(null);
        }
      });
    };
  }

  initializeForm() {
    this.flockForm = this.formBuilder.group({
      description: [this.data.flock?.description || '', Validators.required],
      flock: [this.data.flock?.flock || '', Validators.required],
      placementDateTime: [
        this.data.flock?.placementDateTime
          ? new Date(this.data.flock?.placementDateTime)
          : new Date(),
        Validators.required,
      ],
      numberBirds: [
        this.data.flock?.numberBirds || '',
        Validators.required,
        this.negativeValidator(),
      ],
      classification: [
        this.data.flock?.classification || '',
        Validators.required,
      ],
      breed: [this.data.flock?.breed || '', Validators.required],
      houses: [this.data.flock?.houses || [this.house.guid], Validators.required],
      hatchDate: [
        this.data.flock?.hatchDate
          ? new Date(this.data.flock?.hatchDate)
          : new Date(),
        Validators.required,
      ],
      gender: [
        this.data.flock?.gender
        !== null ? this.genders.find(
            (gender) => gender.value === this.data.flock?.gender
          )?.value
          : '',
        Validators.required,
      ],
      flockSource: [
        this.data.flock?.flockSource !== null
          ? this.sources.find(
            (source) => source.value === this.data.flock?.flockSource
          )?.value
          : '',
        Validators.required,
      ],
      rationCode: [this.data.flock?.rationCode || '', Validators.required],
    });
  }

  onSubmit() {
    if (this.flockForm.valid) {
      this.disableSubmit = true;
      const formData: Flocks = {
        ...this.flockForm.value,
        growerReference: this._flockManagementService.activeView === 'all-flocks' ?
          this.data.flock?.growerReference :
          this.house.growerReference,
        farmNo: this._flockManagementService.activeView === 'all-flocks' ?
          this.data.flock?.farmNo :
          this.house.farmNo,
        houseNum: this.data.flock?.houseNum,
        status: this.data.flock?.status,
      };
      if (this.data.flock?.__id) {
        formData.__id = this.data.flock.__id;
        formData.flock = this.data.flock.flock;
        formData.guid = this.data.flock.guid;
        this._flockManagementService.editRecord('flock', formData as Flocks)
          .then(() => this.dialogRef.close(), () => this.disableSubmit = false);
      } else {
        this._flockManagementService.createNewRecord("flock", formData)
          .then(() => this.dialogRef.close(), () => this.disableSubmit = false);
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
