import {Component, OnInit} from '@angular/core';
import {forkJoin} from 'rxjs';
import {
  Breeds,
  BreedsClient,
  Classifications,
  ClassificationsClient,
  Line,
  LineCounter,
  LineCountersClient,
  LinesClient,
  Measure,
  MeasuresClient,
  Rations,
  RationsClient
} from "@core/services/flock-management.swagger";
import {ClassificationIcon, ClassificationMasterMetadata} from '@core/models/classification';
import {BreedIcon, BreedsMasterMetadata} from '@core/models/breed';
import {AppStateService, ClientProcessingService, CreateConnectedStoreWithLiveData} from '@cat2/legacy-meta-cat';
import {RationIcon, RationMasterMetadata} from '@core/models/ration';
import {SelectOption} from "@cat2/legacy-meta-cat/lib/shared/metadata/select-option";
import {ModelProperty} from "@cat2/legacy-meta-cat/lib/shared/metadata/model-property";
import {MeasuresOverviewMasterMetadata} from "@core/models/measure";
import {LineCountersOverviewMasterMetadata} from "@core/models/line-counter";
import {LinesOverviewMasterMetadata} from "@core/models/line";
import {ModelMetadata} from "@cat2/legacy-meta-cat/lib/shared/metadata/model-metadata";
import CustomStore from 'devextreme/data/custom_store';

interface MasterDataInput {
  type: string;
  dataSource: CustomStore;
  metadata: ModelMetadata;
  icon?: string;
  iconType?: 'mat-icon' | 'fa-icon' | 'mdi-icon' | 'asset';
}

@Component({
  selector: 'flock-management-master-data',
  templateUrl: './flock-management-master-data.component.html',
  styleUrls: ['./flock-management-master-data.component.scss']
})
export class FlockManagementMasterDataComponent implements OnInit {

  categories?: MasterDataInput[];
  selectedCategory: string = '';
  private breeds: Breeds[] | Rations[] | Line[] | LineCounter[] | Measure[] | Classifications[] = [];
  private classifications: Classifications[] = [];
  private rations: Rations[] = [];
  private lines: Line[] = [];
  private lineCounters: Breeds[] | Rations[] | Line[] | LineCounter[] | Measure[] | Classifications[] = [];
  private measures: Breeds[] | Rations[] | Line[] | LineCounter[] | Measure[] | Classifications[] = [];


  constructor(
    private _appState: AppStateService,
    private _process: ClientProcessingService,
    private _breedsClient: BreedsClient,
    private _rationsClient: RationsClient,
    private _linesClient: LinesClient,
    private _lineCountersClient: LineCountersClient,
    private _measuresClient: MeasuresClient,
    private _classificationsClient: ClassificationsClient) {
  }

  ngOnInit(): void {
    this.pullData();
  }

  makeCustomLookupValues: (e: ModelProperty, data: any, type?: string) => SelectOption[] = (e, data, type) => {
    let results: SelectOption[] = [];

    if (e.lookupValues != undefined)
      results = e.lookupValues;

    return results;
  }

  addRow(rowData: any): void {
    switch (this.selectedCategory) {
      case 'rations':
        this.categories = this.categories?.map(category => {
          if (category.type == 'rations') {
            this.rations.push(rowData)
            return {...category, metadata: {...RationMasterMetadata(this.rations as Rations[])}};
          }
          return category;
        });
        break;
      case 'breeds':
        this.categories = this.categories?.map(category => {
          if (category.type == 'breeds') {
            this.breeds.push(rowData)
            return {...category, metadata: {...BreedsMasterMetadata(this.breeds as Breeds[])}};
          }
          return category;
        });
        break;
      case 'classifications':
        this.categories = this.categories?.map(category => {
          if (category.type == 'classifications') {
            this.classifications.push(rowData)
            return {...category, metadata: {...ClassificationMasterMetadata(this.classifications as Classifications[])}};
          }
          return category;
        });
        break;
      case 'lines':
        this.categories = this.categories?.map(category => {
          if (category.type == 'lines') {
            this.lines.push(rowData)
            return {...category, metadata: {...LinesOverviewMasterMetadata(this.lines as Line[])}};
          }
          return category;
        })
        break;
      case 'lineCounters':
        this.lineCounters.push(rowData);
        break;
      case 'measures':
        this.measures.push(rowData);
        break;
    }
  }

  pullData(): void {
    this._appState.showLoadingOverlay();
    forkJoin([
      this._breedsClient.readAllRecords(),
      this._rationsClient.readAllRecords(),
      this._linesClient.readAllRecords(),
      this._lineCountersClient.readAllRecords(),
      this._measuresClient.readAllRecords(),
      this._classificationsClient.readAllRecords()])
      .toPromise().then((result) => {
        if(result) {
          const [breeds,
            rations,
            lines,
            lineCounters,
            measures,
            classifications] = result

          this.breeds = breeds.data;
          this.rations = rations.data;
          this.lines = lines.data;
          this.lineCounters = lineCounters.data;
          this.measures = measures.data;
          this.classifications = classifications.data;

          this.categories = [];

          this.categories.push({
            type: 'breeds',
            metadata: {...BreedsMasterMetadata(breeds.data)},
            dataSource: CreateConnectedStoreWithLiveData(this._breedsClient, this._process),
            icon: BreedIcon
          });
          this.categories.push({
            type: 'classifications',
            metadata: {...ClassificationMasterMetadata(classifications.data)},
            dataSource: CreateConnectedStoreWithLiveData(this._classificationsClient, this._process),
            icon: ClassificationIcon
          });
          this.categories.push({
            type: 'rations',
            metadata: {...RationMasterMetadata(rations.data)},
            dataSource: CreateConnectedStoreWithLiveData(this._rationsClient, this._process),
            icon: RationIcon
          });
          this.categories.push({
            type: 'lines',
            metadata: {...LinesOverviewMasterMetadata(lines.data)},
            dataSource: CreateConnectedStoreWithLiveData(this._linesClient, this._process),
            icon: RationIcon
          });
          this.categories.push({
            type: 'lineCounters',
            metadata: {...LineCountersOverviewMasterMetadata()},
            dataSource: CreateConnectedStoreWithLiveData(this._lineCountersClient, this._process),
            icon: RationIcon
          });
          this.categories.push({
            type: 'measures',
            metadata: {...MeasuresOverviewMasterMetadata()},
            dataSource: CreateConnectedStoreWithLiveData(this._measuresClient, this._process),
            icon: RationIcon
          });
          this._appState.hideLoadingOverlay();
        }
    });
  }
}
