<mat-card appearance="outlined" (click)="navigate()" class="farm-card">
  <div class="header">
    <div class="header-title-actions">
      <span class="farm-description">{{farm!.farmName}}</span>
      <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="editTriggered()">
          <span>Edit</span>
        </button>
        <button mat-menu-item (click)="delete()"
          [class.disabled]="deleteDisabled"
          matTooltip="Cannot delete item because it has Scheduled or Terminated flocks"
          [matTooltipDisabled]="!deleteDisabled"
        >
          <span>Delete</span>
        </button>
      </mat-menu>
    </div>
    <div class="header-sub-title">
      <span>{{farm!.farmCity}}, {{farm!.farmState}}</span>
      <span>{{farm!.growerReference}}</span>
    </div>
  </div>
  <mat-card-content class="card-content-container">
    <div class="content-container">
      <div class="content-title">Grower Name</div>
      <div class="content-subtitle">{{farm!.growerFirstname}} {{farm!.growerLastname}}</div>
    </div>
    <div class="content-container">
      <div class="content-title">Farm Number</div>
      <div class="content-subtitle">{{farm!.farmNo | number}}</div>
    </div>
    <div class="content-container">
      <div class="content-title">Growout Area</div>
      <div class="content-subtitle">{{farm!.growoutArea}}</div>
    </div>
    <div class="content-container">
      <div class="content-title">Add Code</div>
      <div class="content-subtitle">{{farm!.addCode}}</div>
    </div>
  </mat-card-content>
</mat-card>
