import {Injectable} from '@angular/core';
import {AppStateService, ClientProcessingService, FormDialogService} from "@cat2/legacy-meta-cat";
import {
  Breeds,
  BreedsClient,
  Classifications,
  ClassificationsClient,
  FarmMastRecord,
  FarmsClient,
  Flocks,
  FlocksClient,
  Grower,
  GrowersClient,
  House,
  HousesClient,
  PayloadOfFarmMastRecord,
  PayloadOfGrower,
  PayloadOfRegions,
  Rations,
  RationsClient,
  Regions,
  RegionsClient
} from "@core/services/flock-management.swagger";
import {DialogService} from "@core/services/dialog.service";
import {capitalize} from "@core/helpers/capitalize";
import {AddGrowerModalComponent} from '@features/add-grower-modal/add-grower-modal.component';
import {RegionOverviewMetadata} from "@core/entities/region";
import {DialogResult} from "@cat2/legacy-meta-cat/lib/dialog/shared/dialog-result.model";
import {TypedRegion} from "@core/models/typed-region";
import {AddFarmModalComponent} from '@features/add-farm-modal/add-farm-modal.component';
import {AddFlockModalComponent} from '@features/add-flock-modal/add-flock-modal.component';
import {HouseOverviewMetadata} from "@core/entities/house";
import {TypedHouse} from "@core/models/typed-house";
import {BehaviorSubject} from 'rxjs';
import {TypedGrower} from '@core/models/typed-grower';
import {TypedFarm} from '@core/models/typed-farm';
import {NavigationEnd, Router} from "@angular/router";

@Injectable({
  providedIn: 'root',
})
export class FlockManagementService {
  regions?: TypedRegion[] = [];
  growers?: Grower[] = [];
  farms?: FarmMastRecord[];
  houses?: TypedHouse[] = [];
  flocks?: Flocks[] = [];
  breeds?: Breeds[] = [];
  classifications?: Classifications[] = [];
  rations?: Rations[] = [];

  activeView?: string;
  activeItem?: TypedRegion | TypedGrower | TypedFarm | TypedHouse | Flocks;
  searchSubject = new BehaviorSubject<string>('');
  flocksStatusFilter = new BehaviorSubject<string>('all');
  rightClickItem?: TypedRegion | TypedGrower | TypedFarm | TypedHouse | Flocks;

  constructor(
    private _appState: AppStateService,
    private _clientProc: ClientProcessingService,
    private _regionsClient: RegionsClient,
    private _growersClient: GrowersClient,
    private _farmsClient: FarmsClient,
    private _housesClient: HousesClient,
    private _flocksClient: FlocksClient,
    private _breedsClient: BreedsClient,
    private _classificationsClient: ClassificationsClient,
    private _rationClient: RationsClient,
    private _dialogService: DialogService,
    private _formDialogService: FormDialogService,
    private _router: Router
  ) {
    this.subscribeToRouteChanges();
    this.searchSubject.subscribe(v => {
      this.searchedValue = v;
      if (v) {
        this.filterData();
        return;
      }
      this.clearFilters();
    });
  }

  searchedValue = "";
  filteredRegions: TypedRegion[] = [];
  filteredGrowers: Grower[] = [];
  filteredFarms: FarmMastRecord[] = [];
  filteredHouses: House[] = [];
  filteredFlocks: Flocks[] = [];

  clearFilters() {
    this.filteredRegions = this.regions ?? [];
    this.filteredGrowers = this.growers ?? [];
    this.filteredFarms = this.farms ?? [];
    this.filteredHouses = this.houses ?? [];
    this.filteredFlocks = this.flocks ?? [];
    this.filteredRegions.forEach((item) => (item as any).expanded = false);
    this.filteredGrowers.forEach((item) => (item as any).expanded = false);
    this.filteredFarms.forEach((item) => (item as any).expanded = false);
    this.filteredHouses.forEach((item) => (item as any).expanded = false);
    this.filteredFlocks.forEach((item) => (item as any).expanded = false);
  }

  filterData() {
    this.filteredRegions = [];
    this.filteredGrowers = [];
    this.filteredFarms = [];
    this.filteredHouses = [];
    this.filteredFlocks = [];
    this.filterRegions();
  }

  filterRegions() {
    this.filteredRegions = this.regions?.filter(
      (region) => {
        if (region.regionName?.toLowerCase().includes(this.searchedValue.toLowerCase())) {
          this.filterGrowers(region, true);
          return true;
        }
        if (this.regionHasGrowersWithSearchTerm(region)) {
          this.filterGrowers(region, false)
          return true;
        }
        return false;
      }
    ) ?? [];
    if (this.filteredRegions.length && this.searchedValue !== "") {
      this.filteredRegions.forEach((region) => (region as any).expanded = true);
    }
    if (this.searchedValue === "") {
      this.filteredRegions.forEach((region) => (region as any).expanded = false);
    }
  }

  filterGrowers(region: TypedRegion, byPass: boolean) {
    if (byPass) {
      const regionGrowers = this.growers?.filter((g) => region.region === g.region) ?? [];
      regionGrowers.forEach((g) => {
        (g as any).expanded = false;
        this.filterFarms(g, true);
      });
      this.filteredGrowers = this.filteredGrowers.concat(regionGrowers);
      return;
    }

    const regionGrowers = this.growers?.filter((g) => {
        if (region.region != g.region) return false;

        if (g.description?.toLowerCase().includes(this.searchedValue.toLowerCase())) {
          this.filterFarms(g, true);
          return true;
        }
        if (this.growerHasFarmsWithSearchTerm(g)) {
          this.filterFarms(g, false);
          return true;
        }
        return false;
      }
    ) ?? [];
    regionGrowers.forEach((g) => (g as any).expanded = true);
    this.filteredGrowers = this.filteredGrowers.concat(regionGrowers);
  }

  filterFarms(grower: Grower, byPass: boolean) {
    if (byPass) {
      const growerFarms = this.farms?.filter((f) => grower.growerReference === f.growerReference) ?? [];
      growerFarms.forEach((f) => {
        (f as any).expanded = false;
        this.filterHouses(f, true);
      });
      this.filteredFarms = this.filteredFarms.concat(growerFarms);
      return;
    }

    const growerFarms = this.farms?.filter((f) => {
        if (grower.growerReference != f.growerReference) return false;

        if (f.farmName?.toLowerCase().includes(this.searchedValue.toLowerCase())) {
          this.filterHouses(f, true);
          return true;
        }
        if (this.farmHasHousesWithSearchTerm(f)) {
          this.filterHouses(f, false);
          return true;
        }
        return false;
      }
    ) ?? [];
    growerFarms.forEach((g) => (g as any).expanded = true);
    this.filteredFarms = this.filteredFarms.concat(growerFarms);
  }

  filterHouses(farm: FarmMastRecord, byPass: boolean) {
    if (byPass) {
      const farmHouses = this.houses?.filter((h) => farm.growerReference === h.growerReference && farm.farmNo === h.farmNo) ?? [];
      farmHouses.forEach((h) => {
        (h as any).expanded = false;
        this.filterFlocks(h, true);
      });
      this.filteredHouses = this.filteredHouses.concat(farmHouses);
      return;
    }

    const farmHouses = this.houses?.filter((h) => {
        if (farm.growerReference != h.growerReference || farm.farmNo != h.farmNo) return false;

        if (h.houseName?.toLowerCase().includes(this.searchedValue.toLowerCase())) {
          this.filterFlocks(h, true);
          return true;
        }
        if (this.houseHasFlocksWithSearchTerm(h)) {
          this.filterFlocks(h, false);
          return true;
        }
        return false;
      }
    ) ?? [];
    farmHouses.forEach((g) => (g as any).expanded = true);
    this.filteredHouses = this.filteredHouses.concat(farmHouses);
  }

  filterFlocks(house: TypedHouse, byPass: boolean) {
    if (byPass) {
      const houseFlocks = this.flocks?.filter((f) => f.houses?.includes(house.guid)) ?? [];
      houseFlocks.forEach((h) => (h as any).expanded = false);
      this.filteredFlocks = this.filteredFlocks.concat(houseFlocks);
      return;
    }
    this.filteredFlocks = this.flocks?.filter(
      (f) => f.description?.toLowerCase().includes(this.searchedValue.toLowerCase())
    ) ?? [];

    const houseFlocks = this.flocks?.filter((f) => {
        if (!f.houses?.includes(house.guid)) return false;
        return !!f.description?.toLowerCase().includes(this.searchedValue.toLowerCase());
      }
    ) ?? [];
    houseFlocks.forEach((g) => (g as any).expanded = true);
    this.filteredFlocks = this.filteredFlocks.concat(houseFlocks);
  }

  private subscribeToRouteChanges() {
    this._router.events.subscribe(path => {
      if (path instanceof NavigationEnd)
        this.pullData();
    })
  }

  regionHasGrowersWithSearchTerm(region: Regions): boolean {
    const regionGrowers = this.growers?.filter(
      (grower) => grower.region === region.region
    ) ?? [];
    return regionGrowers.some((grower) =>
      grower.description
        ?.toLowerCase()
        .includes(this.searchedValue.toLowerCase())
      || this.growerHasFarmsWithSearchTerm(grower as any)
    );
  }

  growerHasFarmsWithSearchTerm(grower: Grower): boolean {
    const growerFarms = this.farms?.filter(
      (farm) => farm.growerReference === grower.growerReference
    ) ?? [];
    return growerFarms.some((farm) =>
      farm.farmName
        ?.toLowerCase()
        .includes(this.searchedValue.toLowerCase())
      || this.farmHasHousesWithSearchTerm(farm)
    );
  }

  farmHasHousesWithSearchTerm(farm: FarmMastRecord): boolean {
    const farmHouses = this.houses?.filter(
      (house) =>
        house.farmNo === farm.farmNo &&
        house.growerReference === farm.growerReference
    ) ?? [];
    return farmHouses.some((house) =>
      house.houseName
        ?.toLowerCase()
        .includes(this.searchedValue.toLowerCase())
      || this.houseHasFlocksWithSearchTerm(house)
    );
  }

  houseHasFlocksWithSearchTerm(house: House): boolean {
    const houseFlocks = this.flocks?.filter((flock) =>
      flock.houses?.includes(house.guid)
    ) ?? [];
    return houseFlocks.some((flock) =>
      flock.description
        ?.toLowerCase()
        .includes(this.searchedValue.toLowerCase())
    );
  }

  pullData(): void {
    this._appState.showLoadingOverlay();

    Promise.allSettled([
      this._clientProc
        .GetTypedRequestResponse(this._flocksClient.readAllRecords(), 'flock')
        .then((data) => {
          this.flocks = data;
          this.filteredFlocks = data;
        }),
      this._clientProc
        .GetTypedRequestResponse(this._housesClient.readAllRecords(), 'house')
        .then((data) => {
          this.houses = data;
          this.filteredHouses = data;
        }),
      this._clientProc
        .GetTypedRequestResponse(this._farmsClient.readAllRecords(), 'farm')
        .then((data) => {
          this.farms = data;
          this.filteredFarms = data;
        }),
      this._clientProc
        .GetTypedRequestResponse(this._growersClient.readAllRecords(), 'grower')
        .then((data) => {
          this.growers = data;
          this.filteredGrowers = data;
        }),
      this._clientProc
        .GetTypedRequestResponse(this._regionsClient.readAllRecords(), 'region')
        .then((data) => {
          this.regions = data;
          this.filteredRegions = data;
        }),
      this._clientProc
        .GetTypedRequestResponse(this._breedsClient.readAllRecords(), 'breed')
        .then((data) => this.breeds = data),
      this._clientProc
        .GetTypedRequestResponse(this._classificationsClient.readAllRecords(), 'classification')
        .then((data) => this.classifications = data),
      this._clientProc
        .GetTypedRequestResponse(this._rationClient.readAllRecords(), 'ration')
        .then((data) => this.rations = data)
    ]).then(() => {
        this._appState.hideLoadingOverlay();
        this._appState.makePageClean();
      });
  }

  async handleDelete(type?: string) {
    if (await this.confirmDelete(type ? type : this.activeView!)) {
      await this.deleteItem(type ? type : this.activeView!, type ? this.rightClickItem?.__id! : this.activeItem!.__id!);
      this.activeItem = undefined;
      this.activeView = undefined;
    }
  }

  async handleDeleteFromCard(itemType: string, itemId: string) {
    if (await this.confirmDelete(itemType)) {
      await this.deleteItem(itemType, itemId);
    }
  }

  async handleDeleteFlock(flock: Flocks) {
    if (await this.confirmDelete('flock', flock.description)) {
      await this.deleteItem('flock', flock.__id!);
      return;
    }
  }

  async confirmDelete(itemType: string, description?: string) {
    if (itemType == 'flock') {
      return await this._dialogService.openAsyncDialog(
        'Delete Flock',
        'Are you sure you want to delete the flock ' + description + '?'
      );
    }
    return await this._dialogService.openAsyncDialog(
      'Delete ' + capitalize(itemType),
      'Are you sure you want to delete this entry and all its children?'
    );
  }

  async deleteItem(itemType: string, itemId: string) {
    await this._clientProc.ProcessClientCall(
      this.getItemClient(itemType).deleteRecord(itemId),
      () => this.deleteItemOnCollection(itemType, itemId)
    );
  }

  getItemClient(itemType: string): any {
    switch (itemType) {
      case 'region':
        return this._regionsClient;
      case 'grower':
        return this._growersClient;
      case 'farm':
        return this._farmsClient;
      case 'house':
        return this._housesClient;
      case 'flock':
        return this._flocksClient;
      default:
        return;
    }
  }

  deleteItemOnCollection(itemType: string, itemId: string) {
    let collection = this.getItemCollection(itemType);
    let updateIndex = collection.findIndex((i) => i.__id === itemId);
    collection.splice(updateIndex, 1);
  }

  updateItemOnCollection(itemType: string, item: any) {
    let collection = this.getItemCollection(itemType);
    let updateIndex = collection.findIndex((i) => i.__id === item.__id);
    collection[updateIndex] = item;
  }

  getItemCollection(itemType: string): any[] {
    switch (itemType) {
      case 'region':
        return this.regions ?? [];
      case 'grower':
        return this.growers ?? [];
      case 'farm':
        return this.farms ?? [];
      case 'house':
        return this.houses ?? [];
      case 'flock':
        return this.flocks ?? [];
      default:
        return [];
    }
  }

  async handleAdd(itemType: string) {
    let dialogComponent: any;
    let data: any;
    let dialogWidth = 0;
    switch (itemType) {
      case 'grower':
        dialogComponent = AddGrowerModalComponent;
        data = {grower: null};
        dialogWidth = 1200;
        break;
      case 'farm':
        dialogComponent = AddFarmModalComponent;
        data = {farm: null};
        dialogWidth = 900;
        break;
      case 'house':
        await this.addHouse();
        return;
      case 'flock':
        dialogComponent = AddFlockModalComponent;
        data = {flock: null};
        dialogWidth = 1200;
        break;
    }
    await this._dialogService.openAsyncDialogWithContent(
      dialogComponent,
      dialogWidth,
      data
    );
  }

  async handleEdit(
    itemType: string,
    record: Regions | Grower | FarmMastRecord | House | Flocks
  ) {
    let dialogComponent: any;
    let data: any;
    let dialogWidth = 1200;
    switch (itemType) {
      case 'grower':
        dialogComponent = AddGrowerModalComponent;
        data = {grower: record};
        break;
      case 'farm':
        dialogComponent = AddFarmModalComponent;
        data = {farm: record};
        dialogWidth = 900;
        break;
      case 'flock':
        dialogComponent = AddFlockModalComponent;
        data = {flock: record};
        break;
    }
    await this._dialogService.openAsyncDialogWithContent(
      dialogComponent,
      dialogWidth,
      data
    );
  }

  async createNewRecord(
    recordType: string,
    record: Regions | Grower | FarmMastRecord | House | Flocks
  ): Promise<PayloadOfRegions | PayloadOfGrower | PayloadOfFarmMastRecord> {
    let newRecord: any;
    switch (recordType) {
      case 'grower':
        newRecord = await this._growersClient
          .createClass(record as Grower)
          .toPromise();
        this.growers?.push(new Grower({...newRecord.data, type: 'grower'}));
        break;
      case 'farm':
        newRecord = await this._farmsClient
          .createClass(record as FarmMastRecord)
          .toPromise();
        this.farms?.push(
          new FarmMastRecord({...newRecord.data, type: 'farm'})
        );
        break;
      case 'flock':
        newRecord = await this._flocksClient
          .createClass(record as Flocks)
          .toPromise();
        this.flocks?.push(new Flocks(newRecord.data));
        break;
    }
    return newRecord;
  }

  async editRecord(
    recordType: string,
    record: Regions | Grower | FarmMastRecord | House | Flocks
  ): Promise<PayloadOfRegions | PayloadOfGrower | PayloadOfFarmMastRecord> {
    let updatedRecord: any;
    let idx: number | undefined;
    switch (recordType) {
      case 'grower':
        updatedRecord = await this._growersClient
          .updateRecord(record.__id!, record as Grower)
          .toPromise();
        updatedRecord.data.type = 'grower';
        idx = this.growers?.findIndex(
          (g) => g.__id === updatedRecord.data.__id!
        );
        this.growers![idx!] = updatedRecord.data;
        break;
      case 'farm':
        updatedRecord = await this._farmsClient
          .updateRecord(record.__id!, record as FarmMastRecord)
          .toPromise();
        updatedRecord.data.type = 'farm';
        idx = this.farms?.findIndex((g) => g.__id === updatedRecord.data.__id!);

        this.farms![idx!] = updatedRecord.data;
        break;
      case 'flock':
        updatedRecord = await this._flocksClient
          .updateRecord(record.__id!, record as Flocks)
          .toPromise();
        updatedRecord.data.type = 'flock';
        idx = this.flocks?.findIndex(
          (g) => g.__id === updatedRecord.data.__id!
        );
        this.flocks![idx!] = updatedRecord.data;
        break;
    }

    if (this.activeView === updatedRecord.data.type) {
      this.updateItemOnCollection(this.activeView!, updatedRecord.data);
      this.activeItem = updatedRecord.data;
    }
    return updatedRecord;
  }

  async addRegion(): Promise<any> {
    this._formDialogService.ShowForm(
      {},
      RegionOverviewMetadata(this.regions?.map(r=> r.region ?? '')),
      async (formResult: DialogResult<TypedRegion>) => {
        let region: TypedRegion = await this._clientProc.GetRequestResponse(
          this._regionsClient.createClass(formResult.responseData)
        );
        region.type = 'region';
        this.regions!.push(region);
      }
    );
  }

  async editRegion(region?: Regions): Promise<any> {
    this._formDialogService.ShowForm(
      region ? region : this.activeItem,
      RegionOverviewMetadata(),
      async (formResult: DialogResult<Regions>) => {
        let newRegion: TypedRegion | undefined = await this._clientProc.ProcessClientCall(
          this._regionsClient.updateRecord(
            formResult.responseData.__id!,
            formResult.responseData
          )
        );
        if (newRegion) newRegion.type = 'region';
        this.updateItemOnCollection('region', newRegion);
        if (!region || this.activeItem?.guid === newRegion?.guid) this.activeItem = newRegion;
      }
    );
  }

  async addHouse(): Promise<any> {
    this._formDialogService.ShowForm(
      {},
      HouseOverviewMetadata(),
      async (formResult: DialogResult<House>) => {
        formResult.responseData.growerReference = (
          this.activeItem as FarmMastRecord
        ).growerReference;
        formResult.responseData.farmNo = (
          this.activeItem as FarmMastRecord
        ).farmNo;
        let house: TypedHouse = await this._clientProc.GetRequestResponse(
          this._housesClient.createClass(formResult.responseData)
        );
        house.type = 'house';
        this.houses!.push(house);
      }
    );
  }

  async editHouse(house?: House): Promise<any> {
    this._formDialogService.ShowForm(
      house ? house : this.activeItem,
      HouseOverviewMetadata(),
      async (formResult: DialogResult<House>) => {
        let newHouse: TypedHouse | undefined = await this._clientProc.ProcessClientCall(
          this._housesClient.updateRecord(
            formResult.responseData.__id!,
            formResult.responseData
          )
        );
        if (newHouse) newHouse.type = 'house';
        this.updateItemOnCollection('house', newHouse);
        if (!house || this.activeItem?.guid === newHouse?.guid) this.activeItem = newHouse;
      }
    );
  }

  handleFlockFilterStatusChange(status: string) {
    this.flocksStatusFilter.next(status);
  }

  activateItem(event: any) {
    this.activeView = event.type;
    this.activeItem = event;
    if (event.type == 'all-flocks' || event.type == 'house') {
      this.handleFlockFilterStatusChange('A');
    }
  }

  rightClickActivate(event: any) {
    this.rightClickItem = event;
  }

  getItemHasActiveFlock(
    item: TypedRegion | TypedGrower | TypedFarm | TypedHouse | undefined
  ): boolean {
    if (item == undefined || item.type == undefined)
      return true;
    switch (item.type) {
      case 'region':
        const region = item as TypedRegion;
        const regionGrowers = this.growers?.filter(
          (g) => g.region === region.region
        );
        const regionFarms = this.farms?.filter(
          (f) =>
            regionGrowers?.findIndex(
              (g) => g.growerReference === f.growerReference
            ) !== -1
        );
        const regionHouses = this.houses?.filter(
          (h) =>
            regionFarms?.findIndex(
              (f) =>
                f.farmNo === h.farmNo && f.growerReference === h.growerReference
            ) !== -1
        );
        const regionFlocks = this.flocks?.filter(
          (f) =>
            regionHouses?.findIndex((h) => f.houses?.includes(h.guid)) !== -1
        );
        return regionFlocks?.some((f) => f.status !== 'A') ?? false;
      case 'grower':
        const grower = item as TypedGrower;
        const growerFarms = this.farms?.filter(
          (f) => f.growerReference === grower.growerReference
        );
        const growerHouses = this.houses?.filter(
          (h) =>
            growerFarms?.findIndex(
              (f) =>
                f.farmNo === h.farmNo && f.growerReference === h.growerReference
            ) !== -1
        );
        const growerFlocks = this.flocks?.filter(
          (f) =>
            growerHouses?.findIndex((h) => f.houses?.includes(h.guid)) !== -1
        );
        return growerFlocks?.some((f) => f.status !== 'A') ?? false;
      case 'farm':
        const farm = item as TypedFarm;
        const farmHouses = this.houses?.filter(
          (h) =>
            h.farmNo === farm.farmNo &&
            h.growerReference === farm.growerReference
        );
        const farmFlocks = this.flocks?.filter(
          (f) => farmHouses?.findIndex((h) => f.houses?.includes(h.guid)) !== -1
        );
        return farmFlocks?.some((f) => f.status !== 'A') ?? false;
      case 'house':
        const house = item as TypedHouse;
        const houseFlocks = this.flocks?.filter((f) =>
          f.houses?.includes(house.guid)
        );
        return houseFlocks?.some((f) => f.status !== 'A') ?? false;
      default:
        return false;
    }
  }
}
