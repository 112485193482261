import {Component} from '@angular/core';
import {Criteria, SortingService} from "@core/services/sorting.service";


@Component({
  selector: 'app-sort-button',
  templateUrl: './sort-button.component.html',
  styleUrls: ['./sort-button.component.scss']
})
export class SortButtonComponent {

  constructor(
    private _sortingService: SortingService
  ) {
  }

  get orderCriteria(): Criteria[] {
    return this._sortingService.orderCriteria;
  }

  get orderBy() {
    return this._sortingService.orderBy;
  }

  set orderBy(value: any) {
    this._sortingService.orderBy = value;
  }
}
