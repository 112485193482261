import {Component, Input, OnInit} from '@angular/core';
import {FlockManagementService} from "@core/services/flock-management.service";
import {FarmMastRecord, Grower, House, Regions} from "@core/services/flock-management.swagger";

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit {
  @Input() x = 0;
  @Input() y = 0;


  constructor(
    private _flockManagementService: FlockManagementService
  ) {
  }

  ngOnInit(): void {
  }

  get deleteDisabled() {
    return this._flockManagementService.getItemHasActiveFlock(
      this._flockManagementService.rightClickItem!
    );
  }

  async edit() {
    if (this._flockManagementService.rightClickItem instanceof Regions) {
      await this._flockManagementService.editRegion(this._flockManagementService.rightClickItem as Regions);
    }
    if (this._flockManagementService.rightClickItem instanceof Grower) {
      await this._flockManagementService.handleEdit(
        'grower',
        this._flockManagementService.rightClickItem as Grower
      );
    }
    if (this._flockManagementService.rightClickItem instanceof FarmMastRecord) {
      await this._flockManagementService.handleEdit(
        'farm',
        this._flockManagementService.rightClickItem as FarmMastRecord
      );
    }
    if (this._flockManagementService.rightClickItem instanceof House) {
      await this._flockManagementService.editHouse(this._flockManagementService.rightClickItem as House);
    }
  }

  async delete() {
    if (this._flockManagementService.rightClickItem instanceof Regions) {
      await this._flockManagementService.handleDelete('region');
    }
    if (this._flockManagementService.rightClickItem instanceof Grower) {
      await this._flockManagementService.handleDelete('grower');
    }
    if (this._flockManagementService.rightClickItem instanceof FarmMastRecord) {
      await this._flockManagementService.handleDelete('farm');
    }
    if (this._flockManagementService.rightClickItem instanceof House) {
      await this._flockManagementService.handleDelete('house');
    }
  }
}
