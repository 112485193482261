import {Component} from '@angular/core';
import {FlockManagementService} from "@core/services/flock-management.service";
import { FarmMastRecord, Grower } from '@core/services/flock-management.swagger';


@Component({
  selector: 'app-top-details-buttons',
  templateUrl: './top-details-buttons.component.html',
  styleUrls: ['./top-details-buttons.component.scss']
})
export class TopDetailsButtonsComponent {
  constructor(
    private _flockManagementService: FlockManagementService,
  ) {
  }

  async handleDelete() {
    if (!this.deleteDisabled) {
      await this._flockManagementService.handleDelete();
    }
  }

  async handleEdit() {
    if (this._flockManagementService.activeView === 'region') {
      await this._flockManagementService.editRegion();
    }
    if (this._flockManagementService.activeView === 'house') {
      await this._flockManagementService.editHouse();
    }
    if (this._flockManagementService.activeView === 'grower') {
      await this._flockManagementService.handleEdit(
        'grower',
        this._flockManagementService.activeItem as Grower
      );
    }
    if (this._flockManagementService.activeView === 'farm') {
      await this._flockManagementService.handleEdit(
        'farm',
        this._flockManagementService.activeItem as FarmMastRecord
      );
    }
  }

  get deleteDisabled () {
    return this._flockManagementService.getItemHasActiveFlock(
      this._flockManagementService.activeItem!
    );
  }
}
