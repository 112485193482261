<mat-card>
  <mat-card-header>
      <h1>Grower Data</h1>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="growerForm">
      <div class="modal-content">
        <div class="modal-content-top-part">
          <mat-form-field appearance="outline">
            <mat-label>Address</mat-label>
            <input matInput type="text" required formControlName="address"/>
            <mat-error *ngIf="growerForm.get('address')!.invalid">
              Address is required.
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Load Adjustment</mat-label>
            <input matInput type="number" required formControlName="loadAdjustment" min="0"/>
            <mat-error *ngIf="growerForm.get('loadAdjustment')!.invalid">
              Load Adjustment is required.
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>ERP reference</mat-label>
            <input matInput type="text" required formControlName="erpReference"/>
            <mat-error *ngIf="growerForm.get('erpReference')!.invalid">
              ERP reference is required.
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Grade Name</mat-label>
            <input matInput type="text" required formControlName="gradeName"/>
            <mat-error *ngIf="growerForm.get('gradeName')!.hasError('required')">
              Grade Name is required.
            </mat-error>
            <mat-error *ngIf="growerForm.get('gradeName')!.hasError('moreThanOneLetter')">
              Grade Name must be a single letter.
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <input matInput type="text" required formControlName="description"/>
            <mat-error *ngIf="growerForm.get('description')!.invalid">
              Description is required.
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Grower #</mat-label>
            <input matInput type="text" required formControlName="growerReference"/>
            <mat-error *ngIf="growerForm.get('growerReference')!.hasError('required')">
              Grower # is required.
            </mat-error>
            <mat-error *ngIf="growerForm.get('growerReference')!.hasError('min')">
              Grower # must be 3 digits.
            </mat-error>
            <mat-error *ngIf="growerForm.get('growerReference')!.hasError('nonUnique')">
              Grower # must be unique.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="modal-content-middle-part">
          <mat-form-field appearance="outline">
            <mat-label>Grower Type</mat-label>
            <input matInput type="text" required formControlName="growerType"/>
            <mat-error *ngIf="growerForm.get('growerType')!.hasError('required')">
              Grower Type is required.
            </mat-error>
            <mat-error *ngIf="growerForm.get('growerType')!.hasError('moreThanOneLetter')">
              Grower Type must be a single letter.
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Process ID</mat-label>
            <input matInput type="number" required formControlName="processId" min="0"/>
            <mat-error *ngIf="growerForm.get('processId')!.invalid">
              Process ID is required.
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Replication Date</mat-label>
            <input matInput [matDatepicker]="replicationDatePicker" required formControlName="replicationDateTime">
            <mat-datepicker-toggle matSuffix [for]="replicationDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #replicationDatePicker></mat-datepicker>
            <mat-error *ngIf="growerForm.get('replicationDateTime')!.invalid">
              Replication Date is required.
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Service Man</mat-label>
            <input matInput type="number" required formControlName="serviceMan" min="0"/>
            <mat-error *ngIf="growerForm.get('serviceMan')!.invalid">
              Service Man is required.
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <input matInput type="number" required formControlName="status"/>
            <mat-error *ngIf="growerForm.get('status')!.hasError('required')">
              Status is required.
            </mat-error>
            <mat-error *ngIf="growerForm.get('status')!.hasError('invalidStatus')">
              Status must be 1 digit.
            </mat-error>
          </mat-form-field>
          <mat-slide-toggle formControlName="useStandard" class="slider">Use Standard ?</mat-slide-toggle>
        </div>
      </div>
      <div class="modal-content-footer">
        <button mat-flat-button color="warn" (click)="onNoClick()">Cancel</button>
        <button mat-flat-button color="primary" (click)="onSubmit()" [disabled]="growerForm.invalid">Submit</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
