import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlockPageComponent} from './flock-page/flock-page.component';
import {LeftSidebarModule} from '@features/left-sidebar/left-sidebar.module';
import {InfoContainerModule} from "@features/info-container/info-container.module";
import {TopDetailsModule} from "@features/top-details/top-details.module";
import {MiddleDetailsModule} from "@features/middle-details/middle-details.module";
import {ReportsComponent} from './reports/reports.component';
import {Cat2PowerBiReportModule} from '@cat2/legacy-meta-cat';


@NgModule({
  declarations: [
    FlockPageComponent,
    ReportsComponent
  ],
  imports: [
    CommonModule,
    LeftSidebarModule,
    TopDetailsModule,
    MiddleDetailsModule,
    InfoContainerModule,
    Cat2PowerBiReportModule
  ]
})
export class PagesModule {
}
