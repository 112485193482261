import {Component, Inject, OnInit} from '@angular/core';
import {Flocks} from '@core/services/flock-management.swagger';
import {FlockManagementService} from "@core/services/flock-management.service";
import {Cat2CustomStore} from "@cat2/legacy-meta-cat/lib/data-grid/shared/custom-store";
import {ModelMetadata} from "@cat2/legacy-meta-cat/lib/shared/metadata/model-metadata";
import {
  ClientProcessingService,
  CreateConnectedStoreWithLiveData,
  MakeDateProperty,
  MakeNumberProperty,
  MakeStringProperty,
  ModelMetadataBuilder
} from "@cat2/legacy-meta-cat";
import {FlocksGridService} from "@core/services/flocks-grid.service";
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef} from "@angular/material/bottom-sheet";

@Component({
  selector: 'app-flocks-grid',
  templateUrl: './flocks-grid.component.html',
  styleUrls: ['./flocks-grid.component.scss']
})
export class FlocksGridComponent implements OnInit {
  metadata?: ModelMetadata;
  dataSource?: Cat2CustomStore;

  constructor(
    private _flockManagementService: FlockManagementService,
    private _client: FlocksGridService,
    private _process: ClientProcessingService,
    private _bottomSheet: MatBottomSheet
  ) {
  }

  ngOnInit(): void {
    this.loadMetadata();
    this._flockManagementService.flocksStatusFilter.subscribe(() => this.loadDataSource());
  }

  loadDataSource() {
    this.dataSource = CreateConnectedStoreWithLiveData(
      this._client,
      this._process
    );
  }

  loadMetadata() {
    this.metadata = new ModelMetadataBuilder()
      //properties
      .addProperties([
        MakeStringProperty('__id', 'GUID #'),
        MakeStringProperty('status', 'Status'),
        MakeStringProperty('flock', 'Flock ID'),
        MakeStringProperty('description', 'Description'),
        MakeNumberProperty('numberBirds', 'Bird Count'),
        MakeStringProperty('breed', 'Breed'),
        MakeStringProperty('classification', 'Classification'),
        MakeStringProperty('classificationDisplay', 'Classification'),
        MakeDateProperty('placementDateTime', 'Placement Date', "date"),
        MakeDateProperty('hatchDate', 'Hatch Date', "date"),
        MakeStringProperty('gender', 'Gender'),
        MakeStringProperty('genderString', 'Gender'),
        MakeStringProperty('rationCode', 'Ration'),
        MakeStringProperty('flockSource', 'Source'),
        MakeStringProperty('flockSourceDisplay', 'Source'),
        MakeStringProperty('flockHouses', 'Houses')
      ])
      // grid configuration
      .setGridAddMode('none')
      .setGridEditMode('none')
      .setFocusedRowEnabled(false)
      .addGridCustomColumnCaption('Status')
      // grid columns
      .addGridColumn('flock')
      .addGridColumn('numberBirds')
      .addGridColumn('breed')
      .addGridColumn('classificationDisplay')
      .addGridColumn('placementDateTime')
      .addGridColumn('hatchDate')
      .addGridColumn('genderString')
      .addGridColumn('rationCode')
      .addGridColumn('flockSourceDisplay')
      .addGridColumn('flockHouses')
      .makeGridCellClickable('flockHouses')
      .create();
  }

  delete(flock: Flocks) {
    this._flockManagementService.handleDeleteFlock(flock)
      .then(() => this.loadDataSource());
  }

  edit(flock: Flocks) {
    this._flockManagementService.handleEdit('flock', flock)
      .then(() => this.loadDataSource());
  }

  navigateToHouse(house: string, flock: Flocks) {
    const clickedHouse = this._flockManagementService.houses?.find(h => h.houseName == house && h.growerReference == flock.growerReference && h.farmNo == flock.farmNo);
    if (clickedHouse) {
      this._flockManagementService.activateItem(clickedHouse);
    }
  }

  loadStatusColor(status: string) {
    switch (status) {
      case "A":
        return "green"
      case "S":
        return "purple"
      case "T":
        return "red"
      default:
        return ""
    }
  }

  loadStatusTooltip(status: string) {
    switch (status) {
      case "A":
        return "Active"
      case "S":
        return "Scheduled"
      case "T":
        return "Terminated"
      default:
        return ""
    }
  }

  handleClickOnHouse(data: any) {
    // If there's one house only, navigate to that house
    if (data?.data?.flockHouses?.length === 1) {
      this.navigateToHouse(data.data.flockHouses[0], data.data);
      return;
    }

    // If there are more than one, open the mat sheet to select
    const bottomSheetRef = this._bottomSheet.open(HousesSheetComponent, {
      data: data?.data,
      panelClass: 'customBottomSheet'
    });
    bottomSheetRef.afterDismissed().subscribe((value) => {
      this.navigateToHouse(value, data.data);
    });
  }
}

@Component({
  selector: 'app-houses-sheet',
  templateUrl: './houses-sheet.component.html',
  styleUrls: ['./flocks-grid.component.scss']
})
export class HousesSheetComponent {
  houses?: string[];

  constructor(
    private matBottomSheetRef: MatBottomSheetRef<HousesSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data?: any
  ) {
    this.houses = data?.flockHouses;
  }

  selectHouse(h: string) {
    this.matBottomSheetRef.dismiss(h);
  }
}
