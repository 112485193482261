import {MakeStringProperty, ModelMetadataBuilder} from '@cat2/legacy-meta-cat';
import {ModelMetadata} from '@cat2/legacy-meta-cat/lib/shared/metadata/model-metadata';
import * as lodash from 'lodash';

export const RegionIcon = 'terrain';

export const RegionProperties = [
  MakeStringProperty('__id', 'ID'),
  MakeStringProperty('region', 'Region ID'),
  MakeStringProperty('regionName', 'Name'),
  MakeStringProperty('processId', 'Process ID'),
  MakeStringProperty('countryCode', 'Country Code')
];

export function RegionMetadataBuilder(regioIds?: string[]): ModelMetadataBuilder {
  let results = new ModelMetadataBuilder()
    .addProperties(RegionProperties)
    .makeFieldRequired('region')
    .makeFieldRequired('regionName')
    .giveFieldMaxLength('region', 40)
    .giveFieldMaxLength('regionName', 40)
    .giveFieldMaxLength('processId', 15)
    .giveFieldMaxLength('countryCode', 2)
    .addCustomValidation('region', 'Region ID already exists', (i) => {
      if (!i.region || !regioIds?.some(id => id?.toLowerCase() === i?.region?.toLowerCase())) {
        return "";
      }
      return "Region ID already exists";
    })
    .addCustomValidation('region', 'region alphanumeric', (i) => {
      if (!i.region || new RegExp(/^\w+$/).test(i?.region)) {
        return '';
      }
      return "Alphanumeric characters only";
    })
    .addCustomValidation('regionName', 'Region name alphanumeric', (i) => {
      if (!i.regionName || new RegExp(/^\w+$/).test(i.regionName)) {
        return '';
      }
      return "Alphanumeric characters only";
    });
  return lodash.cloneDeep(results);
}


export function RegionOverviewMetadata(regioIds?: string[]): ModelMetadata {
  return {
    ...RegionMetadataBuilder(regioIds)
      .apply(RegionForm)
      .apply(RegionGridColumns)
      .setGridAddMode('dialog')
      .setGridEditMode('form')
      .addGridTitle('Regions')
      .editValueOnlyWhenNew('region')
      .disableRowGrouping()
      .disableColumnFiltering()
      .create()
  };
}

export function RegionGridColumns(builder: ModelMetadataBuilder): ModelMetadataBuilder {
  return builder
    .addGridColumn('region')
    .addGridColumn('regionName')
}

export function RegionForm(builder: ModelMetadataBuilder): ModelMetadataBuilder {
  return builder
    .addFormTitle('Region Data')
    .addPropertyToFormRow('region', 'a')
    .addPropertyToFormRow('regionName', 'a')
}
