<div class="buttons-container">
  <button
    mat-mini-fab
    color="accent"
    (click)="handleEdit()">
    <mat-icon>edit_outline</mat-icon>
  </button>
  <button
    mat-mini-fab
    class="delete"
    [class.delete-disabled]="deleteDisabled"
    matTooltip="Cannot delete item because it has Scheduled or Terminated flocks"
    [matTooltipDisabled]="!deleteDisabled"
    (click)="handleDelete()">
    <mat-icon>delete_outline</mat-icon>
  </button>
</div>
