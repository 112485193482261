import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { ReportsComponent } from '@pages/reports/reports.component';
import {FlockPageComponent} from "@pages/flock-page/flock-page.component";
import {FlockManagementMasterDataComponent} from "@pages/master-data/flock-management-master-data.component";

const routes: Routes = [
  { path: '',  pathMatch: 'full',  redirectTo: 'FlockManagement'},
  { path: 'FlockManagement',  pathMatch: 'full',  component: FlockPageComponent},
  {path: 'dashboard/:id', loadComponent: () => import('@cat2/meta-cat2/reporting').then(m => m.DevExpressDashboardViewerComponent)},
  { path: 'lookups', pathMatch: 'full', component: FlockManagementMasterDataComponent },
  { path: 'reports', pathMatch: 'full', component: ReportsComponent }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
