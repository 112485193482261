import {MakeBooleanProperty, MakeNumberProperty, MakeStringProperty, ModelMetadataBuilder} from '@cat2/legacy-meta-cat';
import {ModelMetadata} from '@cat2/legacy-meta-cat/lib/shared/metadata/model-metadata';
import {Line} from "@core/services/flock-management.swagger";

export const LinesProperties = [
  MakeStringProperty('__id', 'ID'),
  MakeNumberProperty('lineID', 'ID'),
  MakeStringProperty('name', 'Name'),
  MakeStringProperty('description', 'Description'),
  MakeStringProperty('lineType', 'Line Type'),
  MakeStringProperty('active', 'Active'),
  MakeStringProperty('processid', 'Site ID'),
  MakeStringProperty('guidProdlineRunIds', 'Line Run IDs'),
  MakeBooleanProperty('canHoldHolds', 'Can Hold Holds'),
  MakeStringProperty('whichHolds', 'Which Holds'),

];

export function LinesMetadataBuilder(): ModelMetadataBuilder {
  return new ModelMetadataBuilder()
    .addProperties(LinesProperties)
    .makeFieldRequired('name')
    .makeFieldRequired('active')
    .giveFieldMaxLength('lineType', 40)
    .addGridCustomValidation('lineType', "allows alpha-numeric characters only", (i) => new RegExp(/^\w+$/).test(i?.value))
}

export function LinesOverviewMasterMetadata(lines: Line[]): ModelMetadata {
  return LinesMetadataBuilder()
    .apply(builder => LinesGridColumns(builder, lines))
    .apply(LinesForm)
    .setGridAddMode('dialog')
    .setGridEditMode('form')
    .disableRowGrouping()
    .disableGridSearch()
    .disableColumnFiltering()
    .create();
}


export function LinesGridColumns(builder: ModelMetadataBuilder, lines: Line[]): ModelMetadataBuilder {
  return builder
    .addGridTitle('Lines')
    .addGridColumn('lineID')
    .addCustomValidation('lineID', "positive", (i) => i.lineID < 0 ? "Line ID must be positive" : "")
    .addCustomValidation('lineID', "less than 32767", (i) => i.lineID > 32766 ? "Line ID must be less than 32767" : "")
    .addCustomValidation("lineID", "integer", (i) => !Number.isInteger(i.lineID) ? "Line ID must be integer" : "")
    .addCustomValidation("lineID", "unique", (i) => lines.some(l => l.lineID === i.lineID && l.guid !== i.guid) ? "Line ID must be unique" : "")
    .editValueOnlyWhenNew('lineID')
    .addGridColumn('name')
    .addGridColumn('description')
    .addGridColumn('lineType')
    .addCustomValidation("lineType", "uppercase", (i) => i.lineType != i.lineType?.toUpperCase() ? "Line ID must be uppercase" : "")
    .addGridColumn('active')
    .addGridColumn('processid')
    .addGridColumn('guidProdLineRunIds')
    .addGridColumn('canHoldHolds')
    .addGridColumn('whichHolds');
}

export function LinesForm(builder: ModelMetadataBuilder): ModelMetadataBuilder {
  return builder
    .addFormTitle('Lines')
    .addPropertyToFormRow('lineID', 1)
    .addPropertyToFormRow('name', 1)
    .addPropertyToFormRow('description', 2)
    .addPropertyToFormRow('lineType', 1)
    .addPropertyToFormRow('active', 2)
    .addPropertyToFormRow('processid', 2)
    .addPropertyToFormRow('guidProdLineRunIds', 3)
    .addPropertyToFormRow('canHoldHolds', 3)
    .addPropertyToFormRow('whichHolds', 3);
}
