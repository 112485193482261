<mat-card>
  <mat-card-header>
    <h1>Add Farm</h1>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="farmForm">
      <div class="modal-content">
        <div class="modal-content-top-part">
          <mat-form-field appearance="outline">
            <mat-label>Farm #</mat-label>
            <input matInput type="number" formControlName="farmNo" min="0">
            <mat-error *ngIf="farmForm.get('farmNo')!.hasError('required')">Farm No is required.</mat-error>
            <mat-error *ngIf="farmForm.get('farmNo')!.hasError('invalidFarmMinNumber')">
              Farm # must be larger than {{ minFarmNumber }}
            </mat-error>
            <mat-error *ngIf="farmForm.get('farmNo')!.hasError('invalidFarmIntegerNumber')">
              Farm # must be a positive integer
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput type="text" formControlName="farmName" required>
            <mat-error *ngIf="farmForm.get('farmName')!.invalid">Farm Name is required.</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Grower First Name</mat-label>
            <input matInput type="text" formControlName="growerFirstname">
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Grower Last Name</mat-label>
            <input matInput type="text" formControlName="growerLastname">
          </mat-form-field>
        </div>
        <div class="modal-content-middle-part">
          <mat-form-field appearance="outline">
            <mat-label>Growout Area</mat-label>
            <input matInput type="text" formControlName="growoutArea">
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>City</mat-label>
            <input matInput type="text" formControlName="farmCity">
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>State</mat-label>
            <input matInput type="text" formControlName="farmState">
          </mat-form-field>
        </div>
        <mat-form-field appearance="outline">
          <mat-label>Add Code</mat-label>
          <input matInput type="text" formControlName="addCode">
        </mat-form-field>
      </div>
      <div class="modal-content-footer">
        <button mat-flat-button color="warn" (click)="onNoClick()">Cancel</button>
        <button mat-flat-button color="primary" (click)="onSubmit()" [disabled]="farmForm.invalid">Submit</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
