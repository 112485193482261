import {Component, OnInit} from '@angular/core';
import {FlockManagementService} from '@core/services/flock-management.service';
import {MatChipSelectionChange} from "@angular/material/chips";

@Component({
  selector: 'app-status-labels',
  templateUrl: './status-labels.component.html',
  styleUrls: ['./status-labels.component.scss'],
})
export class StatusLabelsComponent implements OnInit {
  status?: string;

  constructor(
    private _flockManagementService: FlockManagementService
  ) {
  }

  ngOnInit(): void {
    this._flockManagementService.flocksStatusFilter.subscribe(s => this.status = s)
  }

  handleChipClick(status: string) {
    this._flockManagementService.handleFlockFilterStatusChange(status);
  }
}
