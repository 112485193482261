<mat-chip-listbox>
  <mat-chip-option color="secondary"
                   [selected]="status === 'all'"
                   [selectable]="status !== 'all'"
                   (click)="handleChipClick('all')">
    <div class="content">
      <div>All Flocks</div>
    </div>
  </mat-chip-option>
  <mat-chip-option color="secondary"
                   [selected]="status === 'A'"
                   [selectable]="status !== 'A'"
                   (click)="handleChipClick('A')">
    <div class="content">
      <app-status-dot [status]="'active'"></app-status-dot>
      <div>&nbsp;Active</div>
    </div>
  </mat-chip-option>
  <mat-chip-option color="secondary"
                   [selected]="status === 'S'"
                   [selectable]="status !== 'S'"
                   (click)="handleChipClick('S')">
    <div class="content">
      <app-status-dot [status]="'scheduled'"></app-status-dot>
      <div>&nbsp;Scheduled</div>
    </div>
  </mat-chip-option>
  <mat-chip-option color="secondary"
                   [selected]="status === 'T'"
                   [selectable]="status !== 'T'"
                   (click)="handleChipClick('T')">
    <div class="content">
      <app-status-dot [status]="'terminated'"></app-status-dot>
      <div>&nbsp;Terminated</div>
    </div>
  </mat-chip-option>
</mat-chip-listbox>
