import {Component, Input} from '@angular/core';
import {House} from '@core/services/flock-management.swagger';
import {FlockManagementService} from "@core/services/flock-management.service";

@Component({
  selector: 'app-house-details',
  templateUrl: './house-details.component.html',
  styleUrls: ['./house-details.component.scss']
})
export class HouseDetailsComponent {
  @Input() house: House | undefined = undefined;

  constructor(
    private _flockManagementService: FlockManagementService
  ) {
  }


  async edit() {
    await this._flockManagementService.editHouse(this.house);
  }

  async delete() {
    if (!this.deleteDisabled) {
      await this._flockManagementService.handleDeleteFromCard('house', this.house?.__id ?? '');
    }
  }

  navigate() {
    this._flockManagementService.activateItem(this.house);
  }

  get deleteDisabled() {
    return this._flockManagementService.getItemHasActiveFlock(this.house!);
  }
}
