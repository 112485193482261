import {Component, OnInit} from '@angular/core';
import {TreeLevel} from '@cat2/legacy-meta-cat/lib/tree/shared/tree-item';
import {TreeIconRule} from '@cat2/legacy-meta-cat/lib/tree/shared/tree-icon-rule';
import {RegionIcon} from '@core/entities/region';
import {GrowerIcon} from '@core/entities/grower';
import {FarmIcon} from '@core/entities/farm';
import {HouseIcon} from '@core/entities/house';
import {FlockManagementService} from '@core/services/flock-management.service';


@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
})
export class TreeComponent implements OnInit {
  hierarchy: TreeLevel[] = [
    {
      type: 'region',
      children: (i) => this.growers.filter((g) => g.region === i.region),
      displayKey: 'regionName',
      typeKey: 'type',
    },
    {
      type: 'grower',
      children: (i) =>
        this.farms.filter((f) => f.growerReference === i.growerReference),
      displayKey: 'description',
      typeKey: 'type',
    },
    {
      type: 'farm',
      children: (i) =>
        this.houses.filter(
          (h) =>
            h.farmNo === i.farmNo && h.growerReference === i.growerReference
        ),
      displayKey: 'farmName',
      typeKey: 'type',
    },
    {
      type: 'house',
      hiddenChildren: (i) => this.flocks.filter((f) => f.houses?.includes(i.guid) && f.status == 'A'),
      displayKey: 'houseName',
      typeKey: 'type',
    },
  ];

  icons: TreeIconRule[] = [
    {type: 'region', icon: RegionIcon, typeKey: 'type'},
    {type: 'grower', icon: GrowerIcon, typeKey: 'type'},
    {type: 'farm', icon: FarmIcon, typeKey: 'type'},
    {type: 'house', icon: HouseIcon, typeKey: 'type'},
  ];

  contextmenu = false;
  contextMenuX = 0;
  contextMenuY = 0;

  get regions() {
    return this._flockManagementService.filteredRegions;
  }

  get growers() {
    return this._flockManagementService.filteredGrowers;
  }

  get farms() {
    return this._flockManagementService.filteredFarms;
  }

  get houses() {
    return this._flockManagementService.filteredHouses;
  }

  get flocks() {
    return this._flockManagementService.filteredFlocks;
  }

  constructor(
    private _flockManagementService: FlockManagementService
  ) {
  }

  ngOnInit(): void {
    window.addEventListener("click", () => {
      this.contextmenu = false;
    });
    window.addEventListener("contextmenu", () => {
      this.contextmenu = false;
    });
  }

  activateItem(event: any) {
    this._flockManagementService.activateItem(event);
  }

  rightClickActivateItem(event: any) {
    this._flockManagementService.rightClickActivate(event);
  }

  onRightClick(event: MouseEvent) {
    event.preventDefault();
    this.contextMenuX = event.clientX
    this.contextMenuY = event.clientY
    setTimeout(() => {
      this.contextmenu = true;
    })
  }
}
