import {Component} from '@angular/core';
import {FlockManagementService} from '@core/services/flock-management.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  searchValue = "";

  constructor(
    private _flockManagementService: FlockManagementService
  ) {
  }

  public get searchString() {
    return this.searchValue;
  }

  public set searchString(value: string) {
    this.searchValue = value || '';
  }

  onSearch() {
    this._flockManagementService.searchSubject.next(this.searchString);
  }

  resetSearchString() {
    this.searchString = "";
    this._flockManagementService.searchSubject.next(this.searchString);
  }
}
