<div class="top-details-container">
  <div class="data">
    <app-farm-data *ngIf="activeView == 'farm'"></app-farm-data>
    <app-grower-data *ngIf="activeView == 'grower'"></app-grower-data>
    <app-region-data *ngIf="activeView == 'region'"></app-region-data>
    <app-house-data *ngIf="activeView == 'house'"></app-house-data>
  </div>
  <span class="spacer"></span>
  <app-top-details-buttons></app-top-details-buttons>
</div>
