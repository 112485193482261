<mat-card>
  <mat-card-header>
    <h1>Flock Data</h1>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="flockForm">
      <div class="modal-content">
        <div class="modal-content-top-part">
          <mat-form-field appearance="outline">
            <mat-label>Flock ID</mat-label>
            <input matInput type="text" required formControlName="flock" [readonly]="isEditMode"/>
            <mat-error *ngIf="flockForm.get('flock')!.invalid">
              Flock ID is required.
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <input matInput type="text" required formControlName="description"/>
            <mat-error *ngIf="flockForm.get('description')!.invalid">
              Description is required.
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Placement Date</mat-label>
            <input matInput [matDatepicker]="placementDatePicker" required formControlName="placementDateTime">
            <mat-datepicker-toggle matSuffix [for]="placementDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #placementDatePicker></mat-datepicker>
            <mat-error *ngIf="flockForm.get('placementDateTime')!.invalid">
              Placement Date is required.
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Number of Birds</mat-label>
            <input matInput type="number" required formControlName="numberBirds" min="0"/>
            <mat-error *ngIf="flockForm.get('numberBirds')!.hasError('required')">
              Number of Birds is required.
            </mat-error>
            <mat-error *ngIf="flockForm.get('numberBirds')!.hasError('invalidNumberOfBirds')">
              Number of birds must be larger than 0.
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Classification</mat-label>
            <mat-select required formControlName="classification">
              <mat-option *ngFor="let option of classifications" [value]="option.classification">
                {{ option!.description }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="flockForm.get('classification')!.hasError('required')">
              Classification is required.
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Breed</mat-label>
            <mat-select required formControlName="breed">
              <mat-option *ngFor="let option of breeds" [value]="option.breed">
                {{ option!.breed }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="flockForm.get('breed')!.hasError('required')">
              Breed is required.
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Houses</mat-label>
            <mat-select required formControlName="houses" multiple>
              <mat-option *ngFor="let option of houses" [value]="option.guid">
                {{ option.houseName }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="flockForm.get('houses')!.hasError('required')">
              At least one house is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="modal-content-middle-part">
          <mat-form-field appearance="outline">
            <mat-label>Hatch Date</mat-label>
            <input matInput [matDatepicker]="hatchDatePicker" required formControlName="hatchDate">
            <mat-datepicker-toggle matSuffix [for]="hatchDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #hatchDatePicker></mat-datepicker>
            <mat-error *ngIf="flockForm.get('hatchDate')!.invalid">
              Hatch Date is required.
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Gender</mat-label>
            <mat-select required formControlName="gender">
              <mat-option *ngFor="let option of genders" [value]="option.value">
                {{ option!.label }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="flockForm.get('gender')!.hasError('required')">
              Gender is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="modal-content-top-part">
          <mat-form-field appearance="outline">
            <mat-label>Source</mat-label>
            <mat-select required formControlName="flockSource">
              <mat-option *ngFor="let option of sources" [value]="option.value">
                {{ option!.label }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="flockForm.get('flockSource')!.hasError('required')">
              Source is required.
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Ration</mat-label>
            <mat-select formControlName="rationCode">
              <mat-option *ngFor="let option of rations" [value]="option.rationCode">
                {{ option!.rationCode }}
              </mat-option>
            </mat-select>
            <!-- <mat-error *ngIf="flockForm.get('rationCode')!.hasError('required')">
            Ration is required.
          </mat-error> -->
          </mat-form-field>
        </div>
      </div>
      <div class="modal-content-footer">
        <button mat-flat-button color="warn" (click)="onNoClick()">Cancel</button>
        <button mat-flat-button color="primary" (click)="onSubmit()" [disabled]="flockForm.invalid || disableSubmit">Submit</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
