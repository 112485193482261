
import { MakeNumberProperty, MakeStringProperty, ModelMetadataBuilder } from '@cat2/legacy-meta-cat';
import { ModelMetadata } from '@cat2/legacy-meta-cat/lib/shared/metadata/model-metadata';
import * as lodash from 'lodash';

export const BreedIcon = 'gradient';

export const BreedProperties = [
  MakeStringProperty('__id', 'ID'),
  MakeStringProperty('breed', 'Code'),
  MakeStringProperty('description', 'Description'),
  MakeStringProperty('productOrigin', 'Product Origin'),
  MakeStringProperty('killPriority', 'Kill Priority'),
  MakeStringProperty('productCode', 'Product Code'),
  MakeStringProperty('condemnProductCode', 'Condemn Product Code'),
  MakeNumberProperty('feedCutoffTime', 'Feed Cutoff Time'),
  MakeNumberProperty('feedRaiseTime', 'Feed Raise Time'),
  MakeNumberProperty('inspectionClass', 'Inspection Class'),
  MakeNumberProperty('antiBioticsFree', 'Antibiotics Free?'),
];

export function BreedsMetadataBuilder(): ModelMetadataBuilder{
  return lodash.cloneDeep(new ModelMetadataBuilder()
    .addProperties(BreedProperties)
    .makeFieldRequired('breed')
    .makeFieldRequired('productCode')
    .makeFieldRequired('condemnProductCode')
    .giveFieldMaxLength('breed', 15)
    .giveFieldMaxLength('description', 30)
    .giveFieldMaxLength('productOrigin', 10)
    .giveFieldMaxLength('productCode', 9)
    .giveFieldMaxLength('condemnProductCode', 9));
}

export function BreedsMasterMetadata(): ModelMetadata {
  return BreedsMetadataBuilder()
    .apply(BreedsGridColumns)
    .apply(BreedsForm)
    .setGridAddMode('grid')
    .setGridEditMode('batch')
    .disableRowGrouping()
    .disableGridSearch()
    .disableColumnFiltering()
    .create();
}

export function BreedsOverviewMetadata(): ModelMetadata {
  return BreedsMetadataBuilder()
    .apply(BreedsGridColumns)
    .apply(BreedsForm)
    .setGridAddMode('dialog')
    .setGridEditMode('form')
    .disableRowGrouping()
    .disableGridSearch()
    .disableColumnFiltering()
    .create();
}
export function BreedsGridColumns(builder: ModelMetadataBuilder): ModelMetadataBuilder{
  return builder
    .addGridTitle('Breeds')
    .addGridColumn('breed')
    .addGridColumn('description')
    .addGridColumn('productCode')
    .addGridColumn('condemnProductCode')
}

export function BreedsForm(builder: ModelMetadataBuilder): ModelMetadataBuilder {
  return builder
    .addFormTitle('Breed Data')
    .addPropertyToFormRow('breed', 'a')
    .addPropertyToFormRow('description', 'a')
    .addPropertyToFormRow('productCode', 'c')
    .addPropertyToFormRow('condemnProductCode', 'c');
}
