import {Component, Input} from '@angular/core';
import {FlockManagementService} from '@core/services/flock-management.service';
import {Grower} from '@core/services/flock-management.swagger';
import {DateTime} from 'luxon';

@Component({
  selector: 'app-grower-details',
  templateUrl: './grower-details.component.html',
  styleUrls: ['./grower-details.component.scss'],
})
export class GrowerDetailsComponent {
  @Input() grower: Grower | undefined = undefined;

  constructor(
    private _flockManagementService: FlockManagementService
  ) {
  }

  async editTriggered() {
    await this._flockManagementService.handleEdit('grower', this.grower!);
  }

  getFormattedDate(grower: Grower) {
    const parsedDate = DateTime.fromJSDate(
      new Date(grower.replicationDateTime! as unknown as string)
    );
    return parsedDate.toFormat('MM/dd/yyyy - hh:mm a');
  }

  async delete() {
    if (!this.deleteDisabled) {
      await this._flockManagementService.handleDeleteFromCard('grower', this.grower?.__id ?? '');
    }
  }

  navigate() {
    this._flockManagementService.activateItem(this.grower);
  }

  get deleteDisabled() {
    return this._flockManagementService.getItemHasActiveFlock(this.grower!);
  }
}
