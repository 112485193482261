import {Component} from '@angular/core';
import {AppStateService, Cat2AppStateModel} from "@cat2/legacy-meta-cat";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'flock-management';

  constructor(private cat2AppState: AppStateService)
    { }

  ngOnInit(): void {
    const state: Cat2AppStateModel = {
      appIcon: 'assets/icon.svg',
      appName: 'FlockManagement'
    }

    this.cat2AppState.setValue(state);

    this.cat2AppState.setValue({
      navBarItems: [
        {
          label: 'Lookups',
          icon: 'qr_code',
          route: 'lookups',
          children: [],
          iconType: 'mat-icon'
        }
      ]
    });
  }
}
