<ng-template #growerDetails let-grower="grower">
  <app-grower-details [grower]="grower"></app-grower-details>
</ng-template>

<ng-template #farmDetails let-farm="farm">
  <app-farm-details [farm]="farm"></app-farm-details>
</ng-template>

<ng-template #houseDetails let-house="house">
  <app-house-details [house]="house"></app-house-details>
</ng-template>

<ng-container *ngIf="activeView === 'house' || activeView === 'all-flocks'">
  <div class="flocks-grid-container">
    <app-flocks-grid></app-flocks-grid>
  </div>
</ng-container>

<ng-container *ngIf="activeView == 'region' || activeView == 'grower' || activeView == 'farm'">
  <cdk-virtual-scroll-viewport itemSize="{{calcItemSize()}}" minBufferPx="{{calcItemSize()}}"
                               maxBufferPx="{{calcItemSize() * 3}}" class="example-viewport">
    <div class="example-item">
      <div *cdkVirtualFor="let item of data" class="example-sub-item">
        <ng-container *ngIf="activeView === 'region'">
          <ng-container *ngTemplateOutlet="growerDetails; context: { grower: item }"></ng-container>
        </ng-container>
        <ng-container *ngIf="activeView === 'grower'">
          <ng-container *ngTemplateOutlet="farmDetails; context: { farm: item }"></ng-container>
        </ng-container>
        <ng-container *ngIf="activeView === 'farm'">
          <ng-container *ngTemplateOutlet="houseDetails; context: { house: item }"></ng-container>
        </ng-container>
      </div>
    </div>
    <app-flocks-grid *ngIf="activeView === 'farm'"></app-flocks-grid>
  </cdk-virtual-scroll-viewport>
</ng-container>

