import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {LegacyMetaCatYesNoDialogComponent} from "@cat2/legacy-meta-cat";

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(
    public dialog: MatDialog
  ) {
  }

  openAsyncDialog(title: string, text: string): Promise<boolean> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      dialogTitle: title,
      dialogText: text
    };
    const dialogRef = this.dialog.open(LegacyMetaCatYesNoDialogComponent, dialogConfig);

    return dialogRef.afterClosed().toPromise();
  }

  openAsyncDialogWithContent(dialog: any, dialogWidth: number, dialogData: any): Promise<boolean> {
    const dialogRef = this.dialog.open(dialog, {
      width: dialogWidth + 'px',
      data: dialogData,
      autoFocus: false
    });
    return dialogRef.afterClosed().toPromise();
  }
}
