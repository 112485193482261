import {Component} from '@angular/core';
import {FlockManagementService} from "@core/services/flock-management.service";

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss'],
})
export class LeftSidebarComponent {
  title = "Grower Resources";

  constructor(
    private _flockManagementService: FlockManagementService
  ) {
  }

  activateItem(event: any) {
    this._flockManagementService.activateItem(event);
  }

  async addRegion(): Promise<any> {
    await this._flockManagementService.addRegion();
  }
}
