import {Component} from '@angular/core';
import {FlockManagementService} from "@core/services/flock-management.service";

@Component({
  selector: 'app-top-details',
  templateUrl: './top-details.component.html',
  styleUrls: ['./top-details.component.scss']
})
export class TopDetailsComponent {
  constructor(
    private _flockManagementService: FlockManagementService
  ) {
  }

  get activeView() {
    return this._flockManagementService.activeView;
  }
}
