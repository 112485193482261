import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators,} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FlockManagementService} from '@core/services/flock-management.service';
import {FarmMastRecord, Grower,} from '@core/services/flock-management.swagger';
import {Observable, of} from "rxjs";

@Component({
  selector: 'app-add-farm-modal',
  templateUrl: './add-farm-modal.component.html',
  styleUrls: ['./add-farm-modal.component.scss'],
})
export class AddFarmModalComponent implements OnInit {
  farmForm: FormGroup = new FormGroup({});

  constructor(
    public dialogRef: MatDialogRef<AddFarmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { farm: FarmMastRecord },
    private formBuilder: FormBuilder,
    private _flockManagementService: FlockManagementService
  ) {
  }

  ngOnInit() {
    this.initializeForm();
  }

  get isEditMode(): boolean {
    return !!this.data?.farm?.__id;
  }

  get grower(): Grower {
    return this._flockManagementService.activeItem as Grower;
  }

  get minFarmNumber(): number {
    const existingFarms = this._flockManagementService.farms
      ?.filter((f) => f.growerReference === this.grower.growerReference);
    if (existingFarms?.length) {
      return parseInt(existingFarms?.sort((a, b) => parseInt(b.farmNo!) - parseInt(a.farmNo!))[0].farmNo!);

    }
    return 0;
  }

  farmNumberValidator(): ValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const value: number = control.value;
      if (!!value && value <= this.minFarmNumber) {
        return of({invalidFarmMinNumber: true});
      }
      if (!!value && !Number.isInteger(value)) {
        return of({invalidFarmIntegerNumber: true});
      }
      if (!!value && /[eE]/.test(value.toString())) {
        return of({invalidFarmIntegerNumber: true});
      }
      return of(null);
    };
  }

  initializeForm() {
    const farmNoValidators = this.isEditMode ? [] : [Validators.required, this.farmNumberValidator()];
    this.farmForm = this.formBuilder.group({
      farmNo: [
        this.data.farm?.farmNo || undefined,
        ...farmNoValidators
      ],
      farmName: [this.data.farm?.farmName || '', Validators.required],
      growerFirstname: [
        this.data.farm?.growerFirstname || ''
      ],
      growerLastname: [
        this.data.farm?.growerLastname || ''
      ],
      growoutArea: [this.data.farm?.growoutArea || ''],
      farmCity: [this.data.farm?.farmCity || ''],
      farmState: [this.data.farm?.farmState || ''],
      addCode: [this.data.farm?.addCode || '']
    });
  }

  onSubmit() {
    if (this.farmForm.valid) {
      if (this.data.farm?.__id) {
        this._flockManagementService
          .editRecord('farm', {
            ...this.farmForm.value,
            __id: this.data.farm.__id,
            guid: this.data.farm.guid,
            type: 'farm',
            growerReference: this.data.farm.growerReference
          })
          .then(() => {
            this.dialogRef.close();
          });
      } else {
        this._flockManagementService
          .createNewRecord('farm', {
            ...this.farmForm.value,
            growerReference: this.grower.growerReference
          })
          .then(() => {
            this.dialogRef.close();
          });
      }
    }
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
