import {Injectable} from '@angular/core';
import {FarmMastRecord, Grower, House} from "@core/services/flock-management.swagger";
import {FlockManagementService} from "@core/services/flock-management.service";

export interface Criteria {
  value?: keyof FarmMastRecord | keyof Grower | keyof House;
  viewValue: string;
}

@Injectable({
  providedIn: 'root'
})
export class SortingService {
  private _orderBy?: keyof FarmMastRecord | keyof Grower | keyof House;

  farmCriteria: Criteria[] = [
    {value: 'farmNo', viewValue: 'Farm Number'},
    {value: 'farmName', viewValue: 'Farm Name'}
  ];

  growerCriteria: Criteria[] = [
    {value: 'growerReference', viewValue: 'Reference'},
    {value: 'description', viewValue: 'Description'},
    {value: 'growerType', viewValue: 'Grower Type'}
  ];

  houseCriteria: Criteria[] = [
    {value: 'houseName', viewValue: 'Name'},
    {value: 'houseSequence', viewValue: 'Sequence'}
  ];

  constructor(
    private _flockManagementService: FlockManagementService
  ) {
  }

  /**
   * Get the order criterion according to the active view.
   * Return the order criterion saved if it corresponds to the Object to order,
   * else return the default order criterion
   */
  get orderBy() {
    switch (this._flockManagementService.activeView) {
      case 'grower':
        return this._orderBy && this.farmCriteria.map(c => c.value!).includes(this._orderBy) ?
          this._orderBy :
          'farmNo';
      case 'region':
        return this._orderBy && this.growerCriteria.map(c => c.value!).includes(this._orderBy) ?
          this._orderBy :
          'growerReference';
      case 'farm':
        return this._orderBy && this.houseCriteria.map(c => c.value!).includes(this._orderBy) ?
          this._orderBy :
          'houseName';
      default:
        return undefined;
    }
  }

  set orderBy(value) {
    this._orderBy = value;
  }

  /**
   * Get the order criteria according to the active view.
   */
  get orderCriteria(): Criteria[] {
    switch (this._flockManagementService.activeView) {
      case 'grower':
        return this.farmCriteria;
      case 'region':
        return this.growerCriteria;
      case 'farm':
        return this.houseCriteria;
      default:
        return [];
    }
  }

  /**
   * Sort a generic array according to the saved or default order criterion.
   * @param data
   */
  sort<T extends Object>(data: T[]): T[] {
    if (data.length > 1) {
      return data.sort(this.createCompareFn(this.orderBy as keyof T, "asc"));
    }
    return data;
  }

  /**
   * Return a compare function for number or string.
   * @param property
   * @param sort_order
   */
  createCompareFn<T extends Object>(property: keyof T, sort_order: "asc" | "desc") {
    return (a: T, b: T) => {
      const val1 = a[property];
      const val2 = b[property];
      const order = sort_order !== "desc" ? 1 : -1;

      switch (typeof val1) {
        case "number": {
          const valB = val2 as unknown as number;
          const result = val1 - valB;
          return result * order;
        }
        case "string": {
          const valB = val2 as unknown as string;
          const result = val1.localeCompare(valB);
          return result * order;
        }
        default:
          return 0;
      }
    };
  }
}
