import {MakeNumberProperty, MakeStringProperty, ModelMetadataBuilder} from '@cat2/legacy-meta-cat';
import {ModelMetadata} from '@cat2/legacy-meta-cat/lib/shared/metadata/model-metadata';
import * as lodash from 'lodash';

export const HouseIcon = 'house_siding';

export const HouseProperties = [
  MakeStringProperty('__id', 'ID'),
  MakeStringProperty('houseName', 'Name'),
  MakeNumberProperty('houseSequence', 'Sequence')
];

export function HouseMetadataBuilder(): ModelMetadataBuilder {
  return lodash.cloneDeep(new ModelMetadataBuilder()
    .addProperties(HouseProperties)
    .makeFieldRequired('houseName')
    .makeFieldRequired('houseSequence')
    .giveFieldMaxLength('houseName', 50)
    .giveFieldMaxValue('houseSequence', 9999));
}

export function HouseOverviewMetadata(): ModelMetadata {
  return HouseMetadataBuilder()
    .apply(HouseForm)
    .apply(HouseGridColumns)
    .addGridTitle('Houses')
    .setGridAddMode('dialog')
    .setGridEditMode('form')
    .disableRowGrouping()
    .disableGridSearch()
    .disableColumnFiltering()
    .create();
}

export function HouseGridColumns(builder: ModelMetadataBuilder): ModelMetadataBuilder {
  return builder
    .addGridColumn('houseName')
    .addGridColumn('houseSequence');
}

export function HouseForm(builder: ModelMetadataBuilder): ModelMetadataBuilder {
  return builder
    .addFormTitle('House Data')
    .addPropertyToFormRow('houseName', 'a')
    .addPropertyToFormRow('houseSequence', 'a')
    .addCustomValidation('houseSequence','Positive number',(house) => {
      if(house.houseSequence < 0)
        return 'Sequence must be positive'
      else
       return ''
    })
    .addCustomValidation('houseSequence','Integer number',(house) => {
      if(!Number.isInteger(house.houseSequence))
        return 'Sequence must be an integer'
      else
       return ''
    });
}
