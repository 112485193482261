<legacy-meta-cat-title-bar [title]="'Farm Data'"></legacy-meta-cat-title-bar>
<div class="row-details">
  <div><strong>Farm Name: </strong>{{activeItem?.farmName}}</div>
  <div><strong>Grower Name: </strong>{{activeItem?.growerFirstname}} {{activeItem?.growerLastname}}</div>
  <div><strong>Growout Area: </strong>{{activeItem?.growoutArea}}</div>
  <div><strong>Add Code: </strong>{{activeItem?.addCode}}</div>
</div>

<div class="row-details">
  <div><strong>Grower Number: </strong>{{activeItem?.growerReference}}</div>
  <div><strong>City/State: </strong>{{activeItem?.farmCity}}, {{activeItem?.farmState}}</div>
  <div><strong>Farm Number: </strong>{{activeItem?.farmNo | number}}</div>
</div>
