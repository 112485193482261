import {MakeNumberProperty, MakeStringProperty, ModelMetadataBuilder} from '@cat2/legacy-meta-cat';
import {ModelMetadata} from '@cat2/legacy-meta-cat/lib/shared/metadata/model-metadata';

export const LineCountersProperties = [
  MakeStringProperty('__id', 'ID'),
  MakeNumberProperty('line', 'Line ID'),
  MakeNumberProperty('counter', 'Counter ID'),
  MakeStringProperty('counterdescription', 'Description')
];

export function LineCountersMetadataBuilder(): ModelMetadataBuilder {
  return new ModelMetadataBuilder()
    .addProperties(LineCountersProperties)
    .makeFieldRequired('line')
    .makeFieldRequired('counter')
    .makeFieldRequired('counterdescription')
    .addGridCustomValidation('line', "must be positive", (i) => !i?.data?.line || i.data.line >= 0)
    .addGridCustomValidation('counter', "must be positive", (i) => !i?.data?.counter || i.data.counter >= 0)
}

export function LineCountersOverviewMasterMetadata(): ModelMetadata {
  return LineCountersMetadataBuilder()
    .apply(LineCountersGridColumns)
    .apply(LineCountersForm)
    .setGridAddMode('grid')
    .setGridEditMode('batch')
    .setGridAddMode('grid')
    .disableRowGrouping()
    .disableGridSearch()
    .disableColumnFiltering()
    .create();
}

export function LineCountersGridColumns(builder: ModelMetadataBuilder): ModelMetadataBuilder {
  return builder
    .addGridTitle('Line Counters')
    .addGridColumn('line')
    .addGridColumn('counter')
    .addGridColumn('counterdescription');
}

export function LineCountersForm(builder: ModelMetadataBuilder): ModelMetadataBuilder {
  return builder
    .addFormTitle('Line Counters')
    .addPropertyToFormRow('line', 2)
    .addPropertyToFormRow('counter', 2)
    .addPropertyToFormRow('counterdescription', 2);
}
