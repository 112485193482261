import {MakeStringProperty, ModelMetadataBuilder} from '@cat2/legacy-meta-cat';
import {ModelMetadata} from '@cat2/legacy-meta-cat/lib/shared/metadata/model-metadata';
import * as lodash from 'lodash';

export const RegionIcon = 'terrain';

export const RegionProperties = [
  MakeStringProperty('__id', 'ID'),
  MakeStringProperty('region', 'Region ID'),
  MakeStringProperty('regionName', 'Name'),
  MakeStringProperty('processId', 'Process ID'),
  MakeStringProperty('countryCode', 'Country Code')
];

export function RegionMetadataBuilder(): ModelMetadataBuilder {
  let results = new ModelMetadataBuilder()
    .addProperties(RegionProperties)
    .makeFieldRequired('region')
    .makeFieldRequired('regionName')
    .giveFieldMaxLength('region', 40)
    .giveFieldMaxLength('regionName', 40)
    .giveFieldMaxLength('processId', 15)
    .giveFieldMaxLength('countryCode', 2);
  return lodash.cloneDeep(results);
}


export function RegionOverviewMetadata(regioIds?: string[]): ModelMetadata {
  return {
    ...RegionMetadataBuilder()
      .apply(RegionForm)
      .apply(RegionGridColumns)
      .setGridAddMode('dialog')
      .setGridEditMode('form')
      .addGridTitle('Regions')
      .addCustomValidation('region', 'Region ID already exists', (i) => {
        if (regioIds?.some(id => id?.toLowerCase() === i?.region?.toLowerCase())) {
          return "Region ID already exists";
        }
        return '';
      })
      .addCustomValidation('region', 'Alpha-Numeric', (i) => {
        if (new RegExp(/^\w+$/).test(i?.region)) {
          return '';
        }
        return "Only alpha-numeric characters allowed";
      })
      .addCustomValidation('regionName', 'Alpha-Numeric', (i) => {
        if (new RegExp(/^\w+$/).test(i?.regionName)) {
          return '';
        }
        return "Only alpha-numeric characters allowed";
      })
      .editValueOnlyWhenNew('region')
      .disableRowGrouping()
      .disableColumnFiltering()
      .create()
  };
}

export function RegionGridColumns(builder: ModelMetadataBuilder): ModelMetadataBuilder {
  return builder
    .addGridColumn('region')
    .addGridColumn('regionName')
}

export function RegionForm(builder: ModelMetadataBuilder): ModelMetadataBuilder {
  return builder
    .addFormTitle('Region Data')
    .addPropertyToFormRow('region', 'a')
    .addPropertyToFormRow('regionName', 'a')
}
