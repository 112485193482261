<mat-card appearance="outlined" (click)="navigate()" class="card-house">
  <div class="header">
    <div class="header-title-actions">
      <span class="house-description">{{house!.houseName}}</span>
      <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="edit()">
          <span>Edit</span>
        </button>
        <button mat-menu-item (click)="delete()"
          [class.disabled]="deleteDisabled"
          matTooltip="Cannot delete item because it has Scheduled or Terminated flocks"
          [matTooltipDisabled]="!deleteDisabled"
        >
          <span>Delete</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <mat-card-content class="card-content-container">
    <div class="content-container">
      <div class="content-title">Sequence</div>
      <div class="content-subtitle">{{house!.houseSequence}}</div>
    </div>
    <div class="content-container">
      <div class="content-title">Farm number</div>
      <div class="content-subtitle">{{house!.farmNo | number}}</div>
    </div>
  </mat-card-content>
</mat-card>
