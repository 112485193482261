import {NgModule} from '@angular/core';
import {SourcesPipe} from './pipes/sources.pipe';

@NgModule({
  declarations: [
    SourcesPipe
  ],
  exports: [
    SourcesPipe
  ],
  imports: [],
  providers: []

})
export class CoreModule {
}
