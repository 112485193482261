import { MakeStringProperty, ModelMetadataBuilder } from '@cat2/legacy-meta-cat';
import { ModelMetadata } from '@cat2/legacy-meta-cat/lib/shared/metadata/model-metadata';
import * as lodash from 'lodash';

export const ClassificationIcon = 'category';

export const ClassificationProperties = [
  MakeStringProperty('__id', 'ID'),
  MakeStringProperty('classification', 'Classification'),
  MakeStringProperty('description', 'Description'),
];

export function ClassificationsMetadataBuilder(): ModelMetadataBuilder {
  return lodash.cloneDeep(
    new ModelMetadataBuilder()
      .addProperties(ClassificationProperties)
      .makeFieldRequired('classification')
      .giveFieldMaxLength('classification', 1)
      .giveFieldMaxLength('description', 20)
  );
}

export function ClassificationMasterMetadata(): ModelMetadata {
  return ClassificationsMetadataBuilder()
    .apply(ClassificationGridColumns)
    .apply(ClassificationsForm)
    .setGridAddMode('grid')
    .setGridEditMode('batch')
    .disableRowGrouping()
    .disableGridSearch()
    .disableColumnFiltering()
    .create();
}

export function ClassificationGridColumns(
  builder: ModelMetadataBuilder
): ModelMetadataBuilder {
  return builder
    .addGridTitle('Classifications')
    .addGridColumn('classification')
    .addGridColumn('description');
}

export function ClassificationsForm(
  builder: ModelMetadataBuilder
): ModelMetadataBuilder {
  return builder
    .addFormTitle('Classification Data')
    .addPropertyToFormRow('classification', 'a')
    .addPropertyToFormRow('description', 'a');
}
