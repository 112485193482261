import {Component} from '@angular/core';
import {FlockManagementService} from "@core/services/flock-management.service";

@Component({
  selector: 'app-flock-page',
  templateUrl: './flock-page.component.html',
  styleUrls: ['./flock-page.component.scss']
})
export class FlockPageComponent {

  constructor(
    private _flockManagementService: FlockManagementService
  ) {
  }

  get activeView() {
    return this._flockManagementService.activeView;
  }
}
