import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-add-button',
  templateUrl: './add-button.component.html',
  styleUrls: ['./add-button.component.scss']
})
export class AddButtonComponent implements OnInit {
  @Input() itemToAdd = '';
  @Output() addClicked = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

}
