import {MakeNumberProperty, MakeStringProperty, ModelMetadataBuilder} from '@cat2/legacy-meta-cat';
import {ModelMetadata} from '@cat2/legacy-meta-cat/lib/shared/metadata/model-metadata';
import {Rations} from "@core/services/flock-management.swagger";

export const RationIcon = 'food_bank';

export const RationProperties = [
  MakeStringProperty('__id', 'ID'),
  MakeStringProperty('rationCode', 'Code'),
  MakeStringProperty('rationDescription', 'Description'),
  MakeNumberProperty('reduceWeight', 'Reduce Weight'),
  MakeNumberProperty('increaseWeight', 'Increase Weight'),
];

export function RationMetadataBuilder(): ModelMetadataBuilder {
  return new ModelMetadataBuilder()
    .addProperties(RationProperties)
    .makeFieldRequired('rationCode')
    .makeFieldRequired('rationDescription')
    .giveFieldMaxLength('rationCode', 1)
    .giveFieldMaxLength('rationDescription', 20)
    .addGridCustomValidation('reduceWeight', "must be positive", (i) => !i?.data?.reduceWeight || i.data.reduceWeight >= 0)
    .addGridCustomValidation('increaseWeight', "must be positive", (i) => !i?.data?.increaseWeight || i.data.increaseWeight >= 0)
    .giveFieldMaxValue('reduceWeight', 999999999.99)
    .giveFieldMaxValue('increaseWeight', 999999999.99)
    .giveFieldMaxLength('rationDescription', 999999999.99);
}

export function RationMasterMetadata(rations: Rations[]): ModelMetadata {
  return RationMetadataBuilder()
    .apply(builder => RationGridColumns(builder, rations))
    .apply(RationsForm)
    .setGridAddMode('dialog')
    .setGridEditMode('form')
    .disableRowGrouping()
    .disableGridSearch()
    .disableColumnFiltering()
    .create();
}

export function RationGridColumns(builder: ModelMetadataBuilder, rations: Rations[]): ModelMetadataBuilder {
  return builder
    .addGridTitle('Rations')
    .addGridColumn('rationCode')
    .addCustomValidation("rationCode", "unique", (i) => rations.some(r => r.rationCode === i.rationCode && r.guid !== i.guid) ? "Code must be unique" : "")
    .addCustomValidation("rationCode", "whitespace", (i) =>   i.rationCode === " " ? "Code must not be whitespace" : "")
    .addGridColumn('rationDescription')
    .addCustomValidation("rationDescription", "whitespace", (i) =>   i.rationDescription?.trim().length === 0 && i.rationDescription.length !== 0 ? "Description must not be whitespace" : "")
    .addGridColumn('reduceWeight')
    .addGridColumn('increaseWeight');
}

export function RationsForm(builder: ModelMetadataBuilder): ModelMetadataBuilder {
  return builder
    .addFormTitle('Classification Data')
    .addPropertyToFormRow('rationCode', 1)
    .addPropertyToFormRow('rationDescription', 1)
    .addPropertyToFormRow('increaseWeight', 2)
    .addPropertyToFormRow('reduceWeight', 2);
}
