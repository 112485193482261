<mat-form-field appearance="outline">
  <mat-label>Search</mat-label>
  <input
    matInput
    [(ngModel)]="searchString"
    (ngModelChange)="onSearch()"
    (keyup.enter)="onSearch()"
  />
  <button
    mat-icon-button
    *ngIf="searchValue.trim() !== ''"
    (click)="resetSearchString()"
    matSuffix
  >
    <mat-icon fontIcon="close"></mat-icon>
  </button>
  <button 
    mat-icon-button 
    matSuffix 
    *ngIf="!searchValue.length"
    >
    <mat-icon fontIcon="search"></mat-icon>
  </button>
</mat-form-field>
