import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TopDetailsComponent} from './top-details.component';
import {MaterialModule} from "@shared/material/material.module";
import {TopDetailsButtonsComponent} from './top-details-buttons/top-details-buttons.component';
import {FarmDataComponent} from './farm-data/farm-data.component';
import {GrowerDataComponent} from './grower-data/grower-data.component';
import {RegionDataComponent} from './region-data/region-data.component';
import {HouseDataComponent} from './house-data/house-data.component';
import {TitleBarModule} from "@cat2/legacy-meta-cat";


@NgModule({
  declarations: [
    TopDetailsComponent,
    TopDetailsButtonsComponent,
    FarmDataComponent,
    GrowerDataComponent,
    RegionDataComponent,
    HouseDataComponent
  ],
    imports: [
        CommonModule,
        MaterialModule,
        TitleBarModule
    ],
  exports: [
    TopDetailsComponent
  ]
})
export class TopDetailsModule {
}
