import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MiddleDetailsComponent} from './middle-details.component';
import {SortButtonComponent} from './sort-button/sort-button.component';
import {AddButtonComponent} from './add-button/add-button.component';
import {StatusDotModule} from "@features/status-dot/status-dot.module";
import {StatusLabelsComponent} from './status-labels/status-labels.component';
import { AddGrowerModalModule } from '@features/add-grower-modal/add-grower-modal.module';
import { AddFarmModalModule } from '@features/add-farm-modal/add-farm-modal.module';
import { AddFlockModalModule } from '@features/add-flock-modal/add-flock.module';
import { MaterialModule } from '@shared/material/material.module';
import {TitleBarModule} from "@cat2/legacy-meta-cat";


@NgModule({
  declarations: [
    MiddleDetailsComponent,
    SortButtonComponent,
    AddButtonComponent,
    StatusLabelsComponent
  ],
    imports: [
        CommonModule,
        MaterialModule,
        StatusDotModule,
        AddGrowerModalModule,
        AddFarmModalModule,
        AddFlockModalModule,
        TitleBarModule
    ],
  exports: [
    MiddleDetailsComponent
  ]
})
export class MiddleDetailsModule {
}
