import {MakeBooleanProperty, MakeNumberProperty, MakeStringProperty, ModelMetadataBuilder} from '@cat2/legacy-meta-cat';
import {ModelMetadata} from '@cat2/legacy-meta-cat/lib/shared/metadata/model-metadata';

export const MeasuresProperties = [
  MakeStringProperty('__id', 'ID'),
  MakeStringProperty('measureID', 'ID'),
  MakeStringProperty('myaction', 'Process ID'),
  MakeStringProperty('measuredescription', 'Description'),
  MakeNumberProperty('criticallimitlo', 'Critical Limit Low'),
  MakeNumberProperty('criticallimithi', 'Critical Limit High'),
  MakeNumberProperty('alarmdelay', 'Alarm Delay'),
  MakeStringProperty('condemncause', 'Condemn Cause'),
  MakeNumberProperty('includesumreport', 'Include Summary Report'),
  MakeNumberProperty('reportsequence', 'Report Sequence'),
  MakeNumberProperty('reportlines', 'Report Lines'),
  MakeNumberProperty('incusdaothertotal', 'Include UDSA Other Total'),
  MakeStringProperty('processid', 'Site ID'),
  MakeNumberProperty('defecttype', 'Defect Type '),
  MakeStringProperty('isveternary', 'Is Veternary'),
  MakeStringProperty('sampletype', 'Sample Type'),
  MakeNumberProperty('alloweditbirdcountweight', 'Allow Edit Bird Count Weight'),
  MakeBooleanProperty('syncchild', 'Sync Children')
];

export function MeasuresMetadataBuilder(): ModelMetadataBuilder {
  return new ModelMetadataBuilder()
    .addProperties(MeasuresProperties)
    .makeFieldRequired('measureID')
    .makeFieldRequired('defecttype')
    .makeFieldRequired('measuredescription')
    .addGridCustomValidation('defecttype', "must be positive", (i) => !i?.data?.defecttype || i.data.defecttype >= 0)
    .addGridCustomValidation('defecttype', "must be an integer", (i) => !i?.data?.defecttype || Number.isInteger(i.data.defecttype))
}

export function MeasuresOverviewMasterMetadata(): ModelMetadata {
  return MeasuresMetadataBuilder()
    .apply(MeasuresGridColumns)
    .apply(MeasuresForm)
    .setGridAddMode('grid')
    .setGridEditMode('batch')
    .setGridAddMode('grid')
    .disableRowGrouping()
    .disableGridSearch()
    .disableColumnFiltering()
    .create();
}

export function MeasuresGridColumns(builder: ModelMetadataBuilder): ModelMetadataBuilder {
  return builder
    .addGridTitle('Measures')
    .addGridColumn('measureID')
    .addGridColumn('measuredescription')
    .addGridColumn('defecttype')
    .addGridColumn('criticallimitlo')
    .addGridColumn('criticallimithi')
    .addGridColumn('condemncause');
}

export function MeasuresForm(builder: ModelMetadataBuilder): ModelMetadataBuilder {
  return builder
    .addFormTitle('Measures')
    .addPropertyToFormRow('measureID', 2)
    .addPropertyToFormRow('measuredescription', 2)
    .addPropertyToFormRow('defecttype', 2)
    .addPropertyToFormRow('criticallimitlo', 2)
    .addPropertyToFormRow('criticallimithi', 2)
    .addPropertyToFormRow('condemncause', 2);
}
