import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {
  API_OVERRIDE,
  BYPASS_CAT2_SPA_CONFIGURATION,
  Cat2AppShellModule,
  Cat2DataGridModule,
  Cat2FormModule,
  MOCK_API,
  Cat2OAuthConfig
} from "@cat2/legacy-meta-cat";
import {environment} from "@environment/environment";
import {OAuthModule} from "angular-oauth2-oidc";
import {PagesModule} from "@pages/pages.module";
import {MaterialModule} from "@shared/material/material.module";
import {CoreModule} from "@core/core.module";
import {HttpClient} from "@angular/common/http";
import {
  API_BASE_URL,
  BreedsClient,
  ClassificationsClient,
  FarmsClient,
  FlocksClient,
  GrowersClient,
  HousesClient,
  LineCountersClient,
  LinesClient,
  MeasuresClient,
  RationsClient,
  RegionsClient
} from "@core/services/flock-management.swagger";
import {FlockManagementMasterDataComponent} from '@pages/master-data/flock-management-master-data.component';

@NgModule({
  declarations: [
    AppComponent,
    FlockManagementMasterDataComponent,
  ],
  imports: [
    CoreModule,
    BrowserModule,
    AppRoutingModule,
    OAuthModule.forRoot(Cat2OAuthConfig),
    Cat2AppShellModule,
    Cat2FormModule,
    PagesModule,
    MaterialModule,
    Cat2DataGridModule,
  ],
  providers: [
    HttpClient,
    RegionsClient,
    GrowersClient,
    HousesClient,
    FarmsClient,
    FlocksClient,
    BreedsClient,
    ClassificationsClient,
    RationsClient,
    MeasuresClient,
    LinesClient,
    LineCountersClient,
    {provide: API_BASE_URL, useValue: environment.url},
    {provide: API_OVERRIDE, useValue: environment.url},
    {provide: MOCK_API, useValue: environment.mockApi},
    {provide: BYPASS_CAT2_SPA_CONFIGURATION, useValue: environment.bypassConfig}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
