import {MakeNumberProperty, MakeStringProperty, ModelMetadataBuilder} from '@cat2/legacy-meta-cat';
import {ModelMetadata} from '@cat2/legacy-meta-cat/lib/shared/metadata/model-metadata';

export const RationIcon = 'food_bank';

export const RationProperties = [
  MakeStringProperty('__id', 'ID'),
  MakeStringProperty('rationCode', 'Code'),
  MakeStringProperty('rationDescription', 'Description'),
  MakeNumberProperty('reduceWeight', 'Reduce Weight'),
  MakeNumberProperty('increaseWeight', 'Increase Weight'),
];

export function RationMetadataBuilder(): ModelMetadataBuilder {
  return new ModelMetadataBuilder()
    .addProperties(RationProperties)
    .makeFieldRequired('rationCode')
    .makeFieldRequired('rationDescription')
    .giveFieldMaxLength('rationCode', 1)
    .giveFieldMaxLength('rationDescription', 20)
    .addGridCustomValidation('reduceWeight', "must be positive", (i) => !i?.data?.reduceWeight || i.data.reduceWeight >= 0)
    .addGridCustomValidation('increaseWeight', "must be positive", (i) => !i?.data?.increaseWeight || i.data.increaseWeight >= 0)
    .giveFieldMaxValue('reduceWeight', 999999999.99)
    .giveFieldMaxValue('increaseWeight', 999999999.99)
    .giveFieldMaxLength('rationDescription', 999999999.99);
}

export function RationMasterMetadata(): ModelMetadata {
  return RationMetadataBuilder()
    .apply(RationGridColumns)
    .apply(RationsForm)
    .setGridAddMode('grid')
    .setGridEditMode('batch')
    .setGridAddMode('grid')
    .disableRowGrouping()
    .disableGridSearch()
    .disableColumnFiltering()
    .create();
}

export function RationGridColumns(builder: ModelMetadataBuilder): ModelMetadataBuilder {
  return builder
    .addGridTitle('Rations')
    .addGridColumn('rationCode')
    .addGridColumn('rationDescription')
    .addGridColumn('reduceWeight')
    .addGridColumn('increaseWeight');
}

export function RationsForm(builder: ModelMetadataBuilder): ModelMetadataBuilder {
  return builder
    .addFormTitle('Classification Data')
    .addPropertyToFormRow('rationCode', 2)
    .addPropertyToFormRow('rationDescription', 2)
    .addPropertyToFormRow('increaseWeight', 2)
    .addPropertyToFormRow('reduceWeight', 2);
}
