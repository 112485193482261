import {NgModule} from '@angular/core';
import {SearchComponent} from './search/search.components';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {LeftSidebarComponent} from './left-sidebar.component';
import {TreeComponent} from "@features/left-sidebar/tree/tree.component";
import {Cat2TreeModule} from "@cat2/legacy-meta-cat";
import {ContextMenuComponent} from './context-menu/context-menu.component';
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatListModule} from "@angular/material/list";

@NgModule({
  declarations: [
    SearchComponent,
    LeftSidebarComponent,
    TreeComponent,
    ContextMenuComponent
  ],
  imports: [
    CommonModule,
    Cat2TreeModule,
    FormsModule,
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    MatListModule
  ],
  exports: [LeftSidebarComponent],
})
export class LeftSidebarModule {
}
