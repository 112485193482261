<mat-card (click)="navigate()" class="grower-card" *ngIf="grower">
  <div class="header">
    <div class="header-title-actions">
      <span class="grower-description">{{grower.description}}</span>
      <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" class="custom-density">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="editTriggered()">
          <span>Edit</span>
        </button>
        <button mat-menu-item (click)="delete()"
          [class.disabled]="deleteDisabled"
          matTooltip="Cannot delete item because it has Scheduled or Terminated flocks"
          [matTooltipDisabled]="!deleteDisabled"
        >
          <span>Delete</span>
        </button>
      </mat-menu>
    </div>
    <div class="header-sub-title">
      <span>{{grower.address}}</span>
      <span>{{grower.growerReference}}</span>
    </div>
  </div>
  <mat-card-content class="card-content-container">
    <div class="content-container">
      <div class="content-title">Grower Type</div>
      <div class="content-subtitle">{{grower.growerType}}</div>
    </div>
    <div class="content-container">
      <div class="content-title">Replication Date</div>
      <div class="content-subtitle">{{getFormattedDate(grower)}}</div>
    </div>
    <div class="content-container">
      <div class="content-title">Grade Name</div>
      <div class="content-subtitle">{{grower.gradeName}}</div>
    </div>
    <div class="content-container">
      <div class="content-title">Uses standard</div>
      <div class="content-subtitle">{{grower.useStandard}}</div>
    </div>
    <div class="content-container">
      <div class="content-title">Process ID</div>
      <div class="content-subtitle">{{grower.processId}}</div>
    </div>
    <div class="content-container">
      <div class="content-title">Service Man</div>
      <div class="content-subtitle">{{grower.serviceMan}}</div>
    </div>
    <div class="content-container">
      <div class="content-title">Load Adjustement</div>
      <div class="content-subtitle">{{grower.loadAdjustment}}</div>
    </div>
    <div class="content-container">
      <div class="content-title">ERP Reference</div>
      <div class="content-subtitle">{{grower.erpReference}}</div>
    </div>
  </mat-card-content>
</mat-card>
