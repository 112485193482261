import { MakeStringProperty, ModelMetadataBuilder } from '@cat2/legacy-meta-cat';
import { ModelMetadata } from '@cat2/legacy-meta-cat/lib/shared/metadata/model-metadata';
import * as lodash from 'lodash';
import {Classifications} from "@core/services/flock-management.swagger";

export const ClassificationIcon = 'category';

export const ClassificationProperties = [
  MakeStringProperty('__id', 'ID'),
  MakeStringProperty('classification', 'Classification'),
  MakeStringProperty('description', 'Description'),
];

export function ClassificationsMetadataBuilder(): ModelMetadataBuilder {
  return lodash.cloneDeep(
    new ModelMetadataBuilder()
      .addProperties(ClassificationProperties)
      .makeFieldRequired('classification')
      .giveFieldMaxLength('classification', 1)
      .giveFieldMaxLength('description', 20)
  );
}

export function ClassificationMasterMetadata(classifications: Classifications[]): ModelMetadata {
  return ClassificationsMetadataBuilder()
    .apply(builder => ClassificationGridColumns(builder, classifications))
    .apply(ClassificationsForm)
    .setGridAddMode('dialog')
    .setGridEditMode('form')
    .disableRowGrouping()
    .disableGridSearch()
    .disableColumnFiltering()
    .create();
}

export function ClassificationGridColumns(
  builder: ModelMetadataBuilder,
  classifications: Classifications[]
): ModelMetadataBuilder {
  return builder
    .addGridTitle('Classifications')
    .addGridColumn('classification')
    .makeFieldRequired('classification')
    .addCustomValidation("classification", "unique", (i) => classifications.some(c => c.classification === i.classification && c.guid !== i.guid) ? "Classification must be unique" : "")
    .addCustomValidation("classification", "whitespace", (i) =>   i.classification?.trim().length === 0 && i.classification.length !== 0 ? "Classification must not be whitespace" : "")
    .addGridColumn('description')
    .makeFieldRequired('description')
    .addCustomValidation("description", "unique", (i) => classifications.some(c => c.description === i.description && c.guid !== i.guid) ? "Description must be unique" : "")
    .addCustomValidation("description", "whitespace", (i) =>   i.description?.trim().length === 0 && i.description.length !== 0 ? "Description must not be whitespace" : "");
}

export function ClassificationsForm(
  builder: ModelMetadataBuilder
): ModelMetadataBuilder {
  return builder
    .addFormTitle('Classification Data')
    .addPropertyToFormRow('classification', 1)
    .addPropertyToFormRow('description', 1);
}
