import {Component} from '@angular/core';
import {FlockManagementService} from "@core/services/flock-management.service";
import {FarmMastRecord} from "@core/services/flock-management.swagger";

@Component({
  selector: 'app-farm-data',
  templateUrl: './farm-data.component.html',
  styleUrls: ['./farm-data.component.scss']
})
export class FarmDataComponent {
  constructor(
    private _flockManagementService: FlockManagementService
  ) {
  }

  get activeItem() {
    if (this._flockManagementService.activeItem instanceof FarmMastRecord) {
      return this._flockManagementService.activeItem;
    }
    return undefined;
  }
}
