import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InfoContainerComponent} from "@features/info-container/info-container.component";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {FlocksGridComponent, HousesSheetComponent} from "@features/info-container/flocks-grid/flocks-grid.component";
import {MaterialModule} from "@shared/material/material.module";
import {GrowerDetailsComponent} from "@features/info-container/growers-details/grower-details.component";
import {FarmDetailsComponent} from "@features/info-container/farm-details/farm-details.component";
import {HouseDetailsComponent} from "@features/info-container/houses-details/house-details.component";
import {StatusDotModule} from "@features/status-dot/status-dot.module";
import {CoreModule} from "@core/core.module";
import {Cat2DataGridModule, LegacyMetaCatStatusDotComponent} from "@cat2/legacy-meta-cat";
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";


@NgModule({
  declarations: [
    InfoContainerComponent,
    GrowerDetailsComponent,
    FarmDetailsComponent,
    FlocksGridComponent,
    HouseDetailsComponent,
    HousesSheetComponent
  ],
  imports: [
    CommonModule,
    ScrollingModule,
    MaterialModule,
    StatusDotModule,
    CoreModule,
    Cat2DataGridModule,
    MatBottomSheetModule,
    LegacyMetaCatStatusDotComponent
  ],
  exports: [
    InfoContainerComponent
  ]
})
export class InfoContainerModule {
}
