<div class="context-menu mat-elevation-z3" [ngStyle]="{'left.px': x, 'top.px': y}">
  <button mat-button
          (click)="edit()">
    Edit
  </button>
  <div matTooltip="Cannot delete item because it has Scheduled or Terminated flocks"
       [matTooltipDisabled]="!deleteDisabled">
    <button mat-button
            (click)="delete()"
            [disabled]="deleteDisabled"
            [ngClass]="deleteDisabled ? 'disabled' : ''">
      Delete
    </button>
  </div>
</div>
