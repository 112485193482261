<div id="flock-container">
  <div class="flock-container-left">
    <app-left-sidebar></app-left-sidebar>
  </div><!--.flock-container-left-->
  <div class="flock-container-right" *ngIf="activeView != undefined">
    <app-top-details *ngIf="activeView != 'all-flocks'"></app-top-details>
    <app-middle-details></app-middle-details>
    <app-info-container></app-info-container>
  </div><!--.flock-container-right-->
</div><!--#flock-container-->
