import {Component} from '@angular/core';
import {FlockManagementService} from "@core/services/flock-management.service";
import {FarmMastRecord, Grower, Regions} from "@core/services/flock-management.swagger";
import {SortingService} from "@core/services/sorting.service";

@Component({
  selector: 'app-info-container',
  templateUrl: './info-container.component.html',
  styleUrls: ['./info-container.component.scss']
})
export class InfoContainerComponent {
  constructor(
    private _flockManagementService: FlockManagementService,
    private _sortingService: SortingService
  ) {
  }

  get activeView() {
    return this._flockManagementService.activeView;
  }

  calcItemSize() {
    if (this.activeView == 'region') {
      return Math.ceil(370 / 3);
    }
    if (this.activeView == 'grower') {
      return Math.ceil(229 / 3);
    }
    if (this.activeView == 'farm') {
      return Math.ceil(115 / 3);
    }
    return 0;
  }

  get data(): any[] {
    switch (this.activeView) {
      case 'region':
        return this._sortingService.sort((this._flockManagementService.growers || []).filter(
          (grower) =>
            grower.region ===
            (this._flockManagementService.activeItem.value! as Regions).region
        ));
      case 'grower':
        return this._sortingService.sort((this._flockManagementService.farms || []).filter(
          (farm) =>
            farm.growerReference ===
            (this._flockManagementService.activeItem.value! as Grower).growerReference
        ));
      case 'farm':
        return this._sortingService.sort((this._flockManagementService.houses || []).filter(
          (house) =>
            house.farmNo ===
            (this._flockManagementService.activeItem.value! as FarmMastRecord)
              .farmNo &&
            house.growerReference ===
            (this._flockManagementService.activeItem.value! as FarmMastRecord)
              .growerReference
        ));
      default:
        return [];
    }
  }
}
