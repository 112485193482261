<div class="left-sidebar">
  <div class="title-container">
    <h2>{{title}}</h2>
    <span class="spacer"></span>
    <button
      mat-icon-button
      (click)="addRegion()"
      matTooltip="Add Region"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <app-search></app-search>
  <div class="scrollable">
    <app-tree></app-tree>
  </div>
  <button
    mat-button
    (click)="activateItem({type: 'all-flocks'})">
    ALL FLOCKS
  </button>
</div>
